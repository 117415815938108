const _trSeoTranslation = {
    "seo_home": {
        "seo_title": "ZION ROPE ACCESS",
        "seo_description": "ZİON İPLE ERİŞİM olarak yurt içi ve yurtdışında bulunan müşterilerimiz için İple Erişim Hizmetleri sunmaktan gurur duyuyoruz."
    },
    "seo_zion_wind": {
        "seo_title": "ZION Wind - Zion Rope Access",
        "seo_description": "Uzman ekibimiz, onarım işleminizin etkili ve verimli bir şekilde tamamlanacağını garanti eder. İşimizi doğru yapmak için gerekli deneyime ve özveriye sahibiz. Sizin için neler yapabileceğimizi görmek için hemen bize ulaşın."
    },
    "seo_zion_wind_blade_services": {
        "seo_title": "RES | Kanat Hizmetleri - Zion Rope Access",
        "seo_description": "Uzman ekibimiz, onarım işleminizin etkili ve verimli bir şekilde tamamlanacağını garanti eder. İşimizi doğru yapmak için gerekli deneyime ve özveriye sahibiz. Sizin için neler yapabileceğimizi görmek için hemen bize ulaşın."
    },

    "seo_zion_wind_tower_services": {
        "seo_title": "RES | Kule Hizmetleri - Zion Rope Access",
        "seo_description": "Uzman ekibimiz, onarım işleminizin etkili ve verimli bir şekilde tamamlanacağını garanti eder. İşimizi doğru yapmak için gerekli deneyime ve özveriye sahibiz. Sizin için neler yapabileceğimizi görmek için hemen bize ulaşın."
    },

    "seo_contact_us": {
        "seo_title": "Bize Ulaşın - Zion Rope Access",
        "seo_description": "Uzman ekibimiz, onarım işleminizin etkili ve verimli bir şekilde tamamlanacağını garanti eder. İşimizi doğru yapmak için gerekli deneyime ve özveriye sahibiz. Sizin için neler yapabileceğimizi görmek için hemen bize ulaşın."
    },


}

export default _trSeoTranslation
