import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import i18next from 'i18next';

// core components

function ZionWindHizmetler() {
  const [showImage1, setShowImage1] = useState(false);
  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);


  return (
    <>
      <div className="section section-examples" data-background-color="black">

        <Container className="text-center">
          <Row>
            <Col>
              <h5 className="text-center"> ZION WIND </h5>
              <h2 className="text-center">RÜZGAR TÜRBİNLERİ HİZMETLERİ</h2>
              <p>Uzman ekibimiz, onarım işleminizin etkili ve verimli bir şekilde tamamlanacağını garanti eder. İşimizi doğru yapmak için gerekli deneyime ve özveriye sahibiz. Sizin için neler yapabileceğimizi görmek için hemen bize ulaşın.</p>
              <div className="space-50"></div>

            </Col>
          </Row>


          <Row>
            <Col lg="4" md="12" onMouseEnter={() => setShowImage1(true)}
              onMouseLeave={() => setShowImage1(false)}>
                <img

                  style={{ opacity: showImage1 ? 0.6 : 1 }}
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/services/kanat-hizmetleri.jpg")}
                ></img>
              <h5 style={{ margin: 20 }}> KANAT HİZMETLERİ </h5>
              <p>ZION Wind olarak, kanat hasarlarına yerinde müdahale ederek, türbini en kısa sürede yeniden faaliyete gecmesini saglamakta ve böylece faaliyet dışı olduğu süre içerisinde oluşan üretim kaybını en aza indirgemekteyiz.</p>
              <Button
                    className="btn-round"
                    style={{ color: 'white' }}
                    color="default"
                    to={"/" + i18next.language + "/res-kanat-hizmetlerimiz"}
                    outline
                    tag={Link}
                  >
                    View More
                  </Button>
            </Col>

            <Col lg="4" md="12" onMouseEnter={() => setShowImage2(true)}
              onMouseLeave={() => setShowImage2(false)}>
              <a href="examples/landing-page.html" target="_blank">
                <img
                  style={{ opacity: showImage2 ? 0.6 : 1 }}

                  alt="..."
                  className="img-raised"
                  src={require("assets/img/services/kule-hizmetleri.jpg")}
                ></img>
              </a>
              <h5 style={{ margin: 20 }}>KULE HİZMETLERİ</h5>
              <p>ZION Wind, Rüzgar Türbini Kulelerinin yüzeyinde oluşan kir ve hasarlara en etkin şekilde erişip, müdahale edebilecek tecrübe, ekipman ve uzman teknisyenlere sahiptir. Uygulanan her bir servis çalışması, üretici talimatına uygun, alanında uzman teknisyenler tarafından sağlanmaktadır.</p>
              <Button
                    className="btn-round"
                    style={{ color: 'white' }}
                    color="default"
                    to={"/" + i18next.language + "/res-kule-hizmetlerimiz"}
                    outline
                    tag={Link}
                  >
                    View More
                  </Button>
            </Col>

            <Col lg="4" md="12" onMouseEnter={() => setShowImage3(true)}
              onMouseLeave={() => setShowImage3(false)}>
              <a href="examples/landing-page.html" target="_blank">
                <img
                  style={{ opacity: showImage3 ? 0.6 : 1 }}
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/services/drone-inspection.jpg")}
                ></img>
              </a>
              <h5 style={{ margin: 20 }}>DRONE INSPECTION</h5>
              <p>Drone Inspection, geleneksel telefotoğrafçılık veya diğer manuel denetim yöntemlerine kıyasla uygun maliyetli ve verimli bir denetim yöntemidir. Bir drone inceleme çözümü ile, rotor kanatlarının durumuna ilişkin yerinde analiz ve değerlendirme için rüzgar türbinleri üzerinde hem görsel hem de termal görüntüler elde edebilirsiniz.</p>

            </Col>





          </Row>
        </Container>
      </div>
    </>
  );
}

export default ZionWindHizmetler;
