import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../../components/Headers/IndexHeader.js";
import DarkFooter from "../../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../../components/Footers/DefaultFooter.js";

// sections for this page
import FooterSection from "../../index-sections/FooterSection.js";
import ImageGallerySection from "../../index-sections/ImageGallerySection.js";

import { Container, Row, Col } from "reactstrap";
import ZionWorld from "../../index-sections/ZionWorld.js";
import { useTranslation } from 'react-i18next';
import MainServices from "../../index-sections/MainServices.js";
import GeotechnicalSolutionHeader from "components/Headers/GeotechnicalSolutionHeader.js";
import CorrosionRepairHeader from "components/Headers/CorrosionRepairHeader.js";
import NtdHeader from "components/Headers/NtdHeader.js";
import GlassFacedeCleaningHeader from "components/Headers/GlassFacedeCleaningHeader.js";

function GlassFacedeCleaning() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <GlassFacedeCleaningHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">
            <Container className="text-left">

              <Row className="justify-content-md-center">

                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/glass-facede-cleaning.webp")}
                  ></img>
                </Col>

                <Col lg="7" md="12">
                  <h5 className="description">
                  İple erişim (endüstriyel dağcılık) yöntemiyle yapılacak cam temizliği diğer yöntemlere göre hem daha hızlıdır, hem daha ekonomiktir hem de daha güvenli olması sebebiyle daha ön plana çıkmaktadır.
                  </h5>

                  <h5 className="description">
                  Yapılarınızın en iyi şekilde görünmesini sağlamak söz konusu olduğunda, iple erişim yöntemiyle yapılan cam temizliği camlarınızı lekesiz tutmanın harika bir yoludur. Camlarınızı temizlenmenin bu benzersiz yöntemi, büyük dış cepheler ve yüksek binaları kir ve lekelerden korumanın en iyi yoludur. Ancak iple erişim yöntemiyle cam temizliği özel ekipman ve eğitim gerektirir.
                  </h5>

                  <h5 className="description">
                  Gökdelenler, plazalar ve yüksek katlı konutlarda iple erişim yöntemiyle camlar nasıl temizlenir? İple erişim uzmanları yüksekte çalışma konusunda eğitimli profesyonellerdir. Bir camı temizlemeden önce binanın çatısında güvenli noktalara kendilerini sabitleyip, özel ekipmanlarla binanınızın en yüksek camlarından başlayıp aşağıya doğru camlarınızı temizler.
                  </h5>

                </Col>

              </Row>






              <div className="space-50"></div>
            </Container>
          </div>
          <MainServices />
          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default GlassFacedeCleaning;
