import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Newsletter from "./Newsletter";
import { useTranslation } from 'react-i18next';
import i18n from "../../Util/i18n";
import { useNavigate } from "react-router-dom";
import i18next from 'i18next';

// core components

function FooterSection() {
  const { t } = useTranslation();
  const navigation = useNavigate()

  return (
    <>
      <div className="section">
        <Newsletter />

        <img style={{ width: '100%', height: '100%' }}
          alt="..."
          src={require("assets/img/divider.png")}
        ></img>
        <Container className="text-left">

          <Row className="justify-content-md-center">
            <Col lg="3" md="12">
              <Row>
                <h5>ZION İPLE ERİŞİM LTD. STI</h5>
              </Row>
              <Row>
                <i style={{ margin: 7 }} className="now-ui-icons ui-1_email-85"></i>
                <p> info@zionropeaccess.com</p>
              </Row>

              <Row>
                <i style={{ margin: 7 }} className="now-ui-icons tech_mobile"></i>
                <p>(+90) 545 503 26 26</p>
              </Row>

              <Row>
                <i style={{ margin: 7 }} className="now-ui-icons tech_mobile"></i>
                <p>(+90) 532 761 91 84</p>
              </Row>

              <Row>
                <i style={{ margin: 7 }} className="now-ui-icons location_pin"></i>
                <p>Adalet Mah. 2132/3 St. No.: 4A, <br /> Bayraklı / IZMIR</p>

              </Row>
              <Row>
                <Col>
                  <img
                    style={{ width: "130px", paddingTop: 9 }}
                    alt="..."
                    className="img"
                    src={require("assets/img/zion-logo.webp")}
                  ></img>
                </Col>
                <Col>

                  <img
                    style={{ width: "130px", margin: 0 }}
                    alt="..."
                    className="img"
                    src={require("assets/img/sprat-logo.webp")}
                  ></img>
                </Col>





              </Row>
            </Col>

            <Col lg='1' md="12"></Col>

            <Col lg='4' md="12">

              <Row>

                <ul style={{listStyleType:'none' }}>
                  <h5>{t("FooterLinks_Col2.title")}</h5>

                  <li>
                    <a href="" onClick={async () => {
                      navigation(`/${i18next.language}/res-kanat-hizmetlerimiz`)
                    }}>                <p >{t("FooterLinks_Col2.blade_service")}</p>
                    </a>

                  </li>
                  <li>
                    <a href="" onClick={async () => {
                      navigation(`/${i18next.language}/res-kule-hizmetlerimiz`)
                    }}>                <p >{t("FooterLinks_Col2.tower_service")}</p>
                    </a>
                  </li>
                </ul>

              </Row>

            </Col>

            <Col lg='4' md="12">
              <Row>

                <ul style={{listStyleType:'none' }}>
                  <h5>{t("FooterLinks_Col3.title")}</h5>

                  <li>   <a href="#" onClick={async () => {
                    navigation(`/${i18next.language}/ndt-tahribatsiz-muayene`)
                  }}>                <p >{t("FooterLinks_Col3.1")}</p>
                  </a></li>
                  <li>
                    <a href="#" onClick={async () => {
                      navigation(`/${i18next.language}/sev-yamac-stabilizasyonu-ve-temizligi`)
                    }}>                <p >{t("FooterLinks_Col3.2")}</p>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={async () => {
                      navigation(`/${i18next.language}/cam-ve-dis-cephe-bakim-onarim-ve-temizligi`)
                    }}>                <p >{t("FooterLinks_Col3.3")}</p>
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={async () => {
                      navigation(`/${i18next.language}/kapali-ve-kisitli-alanlarda-calisma`)
                    }}>                <p >{t("FooterLinks_Col3.4")}</p>
                    </a>
                  </li>

                </ul>









              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FooterSection;
