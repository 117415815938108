import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from "react-i18next";
// core components

function ContactPageHeader() {
  let pageHeader = React.createRef();
  const { t } = useTranslation();


  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div
        className="page-header clear-filter page-header-small"
        filter-color="blue"
      >
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/header1.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        <Container>

          <TypeAnimation
          className="title"

            // Same String at the start will only be typed once, initially
            sequence={[
              t("Slider.description"),
              5000
            ]}
            speed={40} // Custom Speed from 1-99 - Default Speed: 40

            wrapper="h3" // Animation will be rendered as a <span>
            repeat={false} // Repeat this Animation Sequence infinitely
          />


        </Container>

      </div>
    </>
  );
}

export default ContactPageHeader;
