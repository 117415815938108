import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

// core components

function IndexSecondSection() {
  const { t } = useTranslation();

  return (
    <>
      <div className="section">

        <Container className="text-left">
          <Row className="justify-content-md-center">

            <Col lg="8" md="12">
              <h3 className="title">{t("IndexSecondSection.1")}</h3>
              <h5 className="description">
                {t("IndexSecondSection.description1")}</h5>
              <h5 className="description">
                {t("IndexSecondSection.description2")}
              </h5>
              <h5 className="description">
                {t("IndexSecondSection.description3")}
              </h5>
            </Col>
            <Col lg='4' md="12">
              <img
                style={{ width: "100%", margin: 20 }}
                alt="..."
                className="rounded-circle img-raised"
                src={require("assets/img/background-zion.webp")}
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default IndexSecondSection;