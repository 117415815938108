import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../components/Headers/IndexHeader.js";
import DarkFooter from "../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";

// sections for this page
import Images from "../index-sections/Images.js";
import BasicElements from "../index-sections/BasicElements.js";
import Navbars from "../index-sections/Navbars.js";
import Tabs from "../index-sections/Tabs.js";
import Pagination from "../index-sections/Pagination.js";
import Notifications from "../index-sections/Notifications.js";
import Typography from "../index-sections/Typography.js";
import Javascript from "../index-sections/Javascript.js";
import Carousel from "../index-sections/Carousel.js";
import NucleoIcons from "../index-sections/NucleoIcons.js";
import CompleteExamples from "../index-sections/IndexSecondSection.js";
import SignUp from "../index-sections/SignUp.js";
import Examples from "../index-sections/MainServices.js";
import Download from "../index-sections/Download.js";
import FooterSection from "../index-sections/FooterSection.js";
import ImageGallerySection from "../index-sections/ImageGallerySection.js";
import Contact from "../index-sections/Contact.js";

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ZionWindHeader from "components/Headers/Zion-Wind-Header.js";
import { Container, Row, Col } from "reactstrap";
import ZionWindHizmetler from "views/index-sections/ZionWind-Hizmetler.js";
import ZionWorld from "views/index-sections/ZionWorld.js";

function ZionWind() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ZionWindHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">

            <Container className="text-center">
              <Row className="justify-content-md-center">

                <Col lg="12" md="12">
                  <h3 className="title">ZION`A DAİR</h3>
                  <h5 className="description">

ZION`A DAİR
ZİON İPLE ERİŞİM olarak sektörün zirvesinde ve birçok açıdan rakiplerimizin önünde yer alıyoruz. Yurt içi ve yurtdışında bulunan müşterilerimiz için İple Erişim Hizmetleri sunmaktan gurur duyuyoruz.</h5>
                  <h5 className="description">
                  ZİON İPLE ERİŞİM, yurt içi ve yurtdışında yüksek eğim, yükseklik ve boşluk bulunan erişim ve çalışma zorluğu olan her türlü yapıda genellikle iple erişim yöntemi kullanılarak sunulan çok çeşitli hizmetleri saha gerekliliklerine, müşteri şart ve isteklerine uygun olarak sunar. Hizmetlerini kendi ekipleri ile, müşteri ekiplerini yönetip yönlendirerek, danışmanlık sağlayarak ve projelendirerek gerçekleştirebilmektedir.
                  </h5>

                </Col>

              </Row>
            </Container>
          </div>


          <ZionWindHizmetler />
          <ImageGallerySection />
          <ZionWorld/>
          <FooterSection />

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ZionWind;
