/*eslint-disable*/
import React, { useContext, useState } from 'react';

// reactstrap components
import {
  Container, Row, Col, Button,
} from "reactstrap";
// core components
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import WidioneLogo from 'components/Logo';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function WorkingAtClosedHeader() {
  let pageHeader = React.createRef();
  const { t } = useTranslation();
  const [backgroundImage, setbackgroundImage] = useState('work-at-close.webp')

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header  clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            transition: "background 0.3s linear",
            backgroundImage: "url(" + require(`assets/img/services/${backgroundImage}`) + ")"
          }}
          ref={pageHeader}
        >
        </div>
        <Container>
          <div className="content-center brand">
          <div>
          <h3>ZION INDUSTRIAL</h3>

            <TypeAnimation
              // Same String at the start will only be typed once, initially
              sequence={[
                () => {
                  console.log('Done 1!');
                },
                'KAPALI VE KISITLI ALANLARDA ÇALIŞMA',
                5000,

              ]}
              speed={70} // Custom Speed from 1-99 - Default Speed: 40
              wrapper="h1" // Animation will be rendered as a <span>
              repeat={Infinity} // Repeat this Animation Sequence infinitely
            />
            </div>
          </div>

          <h6 className="category category-absolute">
            Made by {" "}
            <a href="http://widione.com" target="_blank">
              <WidioneLogo style={{ width: "5%" }} />
            </a>
          </h6>
        </Container>
      </div>
    </>
  );
}

export default WorkingAtClosedHeader;
