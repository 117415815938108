import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../../components/Headers/IndexHeader.js";
import DarkFooter from "../../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../../components/Footers/DefaultFooter.js";

// sections for this page
import FooterSection from "../../index-sections/FooterSection.js";
import ImageGallerySection from "../../index-sections/ImageGallerySection.js";

import { Container, Row, Col } from "reactstrap";
import ZionWorld from "../../../views/index-sections/ZionWorld.js";
import ZionIndustrialHeader from "../../../components/Headers/ZionIndustrialHeader.js";
import { useTranslation } from 'react-i18next';
import MainServices from "../../index-sections/MainServices.js";
import GeotechnicalSolutionHeader from "components/Headers/GeotechnicalSolutionHeader.js";

function GeoTechnicalSolutions() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <GeotechnicalSolutionHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">
            <Container className="text-left">

              <Row className="justify-content-md-center">

                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/kaya-ve-sev-temizligi.webp")}
                  ></img>
                </Col>

                <Col lg="8" md="12">
                  <h2 className="title">Kaya ve Şev Temizliği</h2>
                  <h5 className="description">
                    Kaya ve şev temizliği genel olarak, serbest konumda bulunan veya yapılan analizler sonucunda riskli olarak değerlendirilen kaya ve taşların, set ve oyuklardan çıkarılması olarak tanımlanmaktadır. Kaya ve Şev Temizliği genellikle basit el aletleri kullanılarak, düşme veya kayma tehlikesi barındıran yüzey malzemelerinin çıkarılmasıyla yapılır. Büyük kaya bloklarının temizlenmesi gerektiği durumlarda ise, daha kapsamlı endüstriyel alet ve makineler kullanılmalıdır.
                  </h5>

                  <h5 className="description">
                    Geçici kaya düşmesi koruma önlemleri, ancak gerekli temizlik işlemleri tamamlandığında, güvenlik önlemi olarak uygulanabilmektedirler. Kaya ve taş temizleme uygulamaları risk azaltma önlemleri olarak değerlendirilse de, risk oranı yüksek olan, daha kapsamlı çalışma gerektiren lokasyonlarda yalnızca geçici çözümler olarak görülmelilerdir. Şev temizliği, stabilizasyon projelerinin ilk adımı olarak değerlendirilebilmektedir.
                  </h5>
                </Col>

              </Row>

              <div className="space-50"></div>

              <Row className="justify-content-md-center">
                <Col lg="8" md="12">
                  <h2 className="title">Kaya Bariyeri</h2>
                  <h5 className="description">
                    Kaya Bariyerlerinin amacı, kaya düşmesi olayından sonra hareketli kütlenin hareketini, etkilenen bölgedeki varlıkları tehlikeye atmayacak şekilde durdurmaktır. Bu gibi koruyucu mühendislik yapıları tasarlanırken, hareketli kaya bloğunun koruyucu yapı ile temas ettiğinde sahip olacağı enerji miktarı ve lokasyonun geometrik koşulları gibi etkenler dikkate alınmalıdır.
                  </h5>

                  <h5 className="description">
                    Kaya Bariyerleri genel tabir ile 100kJ ile 12.000kJ arasındaki enerjiyi absorbe etme özelliğine sahip esnek koruyucu yapılardır. Bileşenlerinin bükülmesi ve yer değiştirmesi yoluyla enerji emen kaya bariyerleri, emilecek enerji miktarı, durdurulacak hareketli cismin kütlesi ve hızı dikkate alınarak seçilmelidir.
                  </h5>

                  <h5 className="description">
                    ZION Industrial olarak, ihtiyaçlarınızı tam ve doğru bir şekilde belirlemek için tasarım ve mühendislik hizmetleri sunmaktayız. Satın almış olduğunuz bariyerler ve çözümler için kontrol ve uygunluk belirleme hizmetleri, ankraj ve cıvata çekme testleri gibi hizmetler sunar, talep etmiş olduğunuz çözümler için uygunluk raporları ve hesap kontrolleri gibi hizmetler de verilmekteyiz.
                  </h5>
                </Col>

                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/kaya-bariyeri.webp")}
                  ></img>
                </Col>
              </Row>

              <div className="space-50"></div>

              <Row className="justify-content-md-center">
                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/celik-ag.webp")}
                  ></img>
                </Col>
                <Col lg="8" md="12">
                  <h2 className="title">Çelik Ağ</h2>
                  <h5 className="description">
                    Çelik ağ veya diğer bilinen şekliyle çelik grid kaya düşmesini ve dökülmeyi önleme amacıyla kullanılan tel örgüdür. Basit tel ağ gibi pasif sistemler, ana kaya kütlesinden kopan parçaların yüzey boyunca hareketini kısıtlayarak şevin alt kısmının düşme olayından etkilenmesini minimize etmek amacıyla kullanılan sistemlerdir.
                  </h5>

                  <h5 className="description">
                    Çelik Örgüler, yamaç eteğinde bariyer veya hendek oluşturmak için yeterli alanın olmadığı durumlarda koruyucu önlem olarak kullanılan tel örgüler, yırtılmadan belirli bir miktar enerjiyi emmek için çok sayıda çelik tel veya halat örülerek yapılan kalıcı kaplama yapılardır. Tel örgü, örgünün şeve montajlandığı (tel ankrajların kullanılmasını tavsiye ederiz), şev yüzeyine yerleştirildiği veya kaya delinmesi gibi uygulamalarda tek başına kullanılır. Ağ, eğimli yüzeydeki serbest konumda bulunan taşları destekler. Yamaçların üst, yüzey ve alt kısımlarına ankrajlarla sabitlenir.
                  </h5>

                  <h5 className="description">
                    Sabit hat uygulamasının temel avantajı, depreme bağlı yer hareketleri sırasında sistemin, esnekliği sayesinde, azami derecede performans kaybıyla çalışmaya devam etmesidir.
                  </h5>
                  <h5 className="description">
                    ZION Industrial olarak, bu yüksek mukavemetli çelik örgü sistemleri, ürün performansı basit altıgen bükümlü çift örgüden çok daha üstün olduğundan, tercih etmekteyiz.
                  </h5>
                </Col>
              </Row>
              <div className="space-50"></div>
            </Container>
          </div>
          <MainServices />
          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default GeoTechnicalSolutions;
