import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../components/Headers/IndexHeader.js";
import DarkFooter from "../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";

// sections for this page
import Images from "../index-sections/Images.js";
import BasicElements from "../index-sections/BasicElements.js";
import Navbars from "../index-sections/Navbars.js";
import Tabs from "../index-sections/Tabs.js";
import Pagination from "../index-sections/Pagination.js";
import Notifications from "../index-sections/Notifications.js";
import Typography from "../index-sections/Typography.js";
import Javascript from "../index-sections/Javascript.js";
import Carousel from "../index-sections/Carousel.js";
import NucleoIcons from "../index-sections/NucleoIcons.js";
import CompleteExamples from "../index-sections/IndexSecondSection.js";
import SignUp from "../index-sections/SignUp.js";
import Examples from "../index-sections/MainServices.js";
import Download from "../index-sections/Download.js";
import FooterSection from "../index-sections/FooterSection.js";
import ImageGallerySection from "../index-sections/ImageGallerySection.js";
import Contact from "../index-sections/Contact.js";

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ZionWindHeader from "components/Headers/Zion-Wind-Header.js";
import { Container, Row, Col } from "reactstrap";
import ZionWindHizmetler from "views/index-sections/ZionWind-Hizmetler.js";
import ZionWorld from "views/index-sections/ZionWorld.js";
import ZionWindBladeServicesHeader from "components/Headers/ZionWindBladeServicesHeader.js";

function BladeServices() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ZionWindBladeServicesHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">


            <Container className="text-left">
              <Row className="justify-content-md-center">

                <Col lg="7" md="12">
                  <h5 className="description">
                    ZION Wind, rüzgar türbini kanatlarının daha uzun süre hizmet edebilmelerini saglayan yıllık periyodik kontrolleri düzenli olarak yapabilecek, hasarları henüz oluşum aşamasında tespit edebilecek, gerektiğinde kanat tipine ve yapısına göre bakımını ve tamirini yapabilecek deneyimli bir kadroya sahiptir. Kanat kompozit onarımı, alanında uzman teknisyenler gerektirir. İşin kalitesi, teknisyenin titiz çalışması ve beceri seviyesine bağlıdır.


                  </h5>

                  <h5 className="description">

                    ZION Wind olarak, kanat hasarlarına yerinde müdahale ederek, türbini en kısa sürede yeniden faaliyete gecmesini saglamakta ve böylece faaliyet dışı olduğu süre içerisinde oluşan üretim kaybını en aza indirgemekteyiz. Teknisyen kadromuz, ülkemizde ve yurtdışında bir çok rüzgar türbini sahasında görev almış, deneyimli uzmanlardan oluşmaktadır. RES projelerimizde görev alan teknisyenlerimiz; başta GWO ST (Global Wind Organisation Safety Training) ve IRATA International (Industrial Rope Access Trade Association) olmak üzere, bir dizi akredite özel eğitimi başarılı bir şekilde tamamlamışlardır.      </h5>
                </Col>
                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/kanat-hizmetleri-1.webp")}
                  ></img>
                </Col>
              </Row>

              <div className="space-50"></div>

              <Row className="justify-content-md-center">


                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/kanat-hasar-tamiri.webp")}
                  ></img>
                </Col>

                <Col lg="7" md="12">
                  <h2 className="title">KANAT HASAR TAMİRİ</h2>
                  <h5 className="description">
                  Rüzgar Türbin kanatları, yağmur, kar, buz, dolu, kum, sıcaklık, rüzgar ve yıldırım gibi akla gelebilecek en aşırı hava koşullarına maruz kalmaktadır. Çevresel etkilere maruz kalan kanat zamanla zarar görür. Çatlaklar Yıldırım düşmesiyle oluşan büyük hasarlar kanat aerodinamiği etkiler, erken müdahale edilmezse hasar büyür ve türbin verimliliği düşer.

                  </h5>

                  <h5 className="description">
                  ZION Wind kanat tamir teknisyenleri, çevresel nedenlerle hasar görmüş kanat yüzeylerine en etkin müdahaleyi en kısa zamanda, yerinde müdahale edebilecek, uzman teknisyen, ekipman ve tecrübeye sahiptir. Yaptığımız uygulamaların tamamı, türbin markasına ve kullanılan kanat yapısına göre, üretici talimatı doğrultusunda gerçekleştirilir. ZION Wind olarak sağladığımız hizmetin önemini anlıyor ve kanat teknisyenlerimizin uygulamalarının ve uzmanlığının gelecekteki referansımız olduğunun farkındayız.

                    </h5>

                </Col>
              </Row>


              <div className="space-50"></div>

<Row className="justify-content-md-center">



  <Col lg="7" md="12">
    <h2 className="title">KANAT BAKIM</h2>
    <h5 className="description">
    Kanat kontrol raporunda tespit edilen küçük hasarlara hızlı müdahale etmek, kanadın ömrünü ve üretim verimliliğini % 20 – 30 oranında arttırır. Teknisyenleri, hücum ve firar kenar aşınması tamiri, kanadın balanslanması, ufak kılcal çatlak ve deliklerin kapatılması, yıldırım koruması tamiri ve boya uygulamaları gibi hizmetleri sağlar. ZION Wind teknisyenleri Kanat Bakım Servisinin Kontrol Raporu sonrası yada Kontrol sırasında giderilmesini önerir. Düzenli bakım ve kontrol müşteri yatırımlarının uzun ömürlü olmasını sağlayacaktır.
    </h5>


  </Col>


  <Col lg='4' md="12">
    <img
      className="circle img-raised"
      style={{ width: "100%" }}
      alt="..."
      src={require("assets/img/services/kanat-bakimi.webp")}
    ></img>
  </Col>
</Row>

<div className="space-50"></div>

<Row className="justify-content-md-center">

<Col lg='4' md="12">
    <img
      className="circle img-raised"
      style={{ width: "100%" }}
      alt="..."
      src={require("assets/img/services/kanat-kontrol.webp")}
    ></img>
  </Col>

  <Col lg="7" md="12">
    <h2 className="title">KANAT KONTROL</h2>
    <h5 className="description">
    Kanatların genel durumunu bilmek, zamanında kontrol etmek kanat ömrünü uzatmak için akılcı bir stratejidir. Kontroller hücum ve firar kenarları, yıldırım koruması, yüzey bileşenleri ve kanat tip kısmına uygulanır. ZION Wind teknisyenleri, tespit edilen hasarlara 32 saat içerisinde müdahale edebilir. ZION Wind kanat teknisyenleri, kanatlarınızın kontrolünü her yıl düzenli olarak yapılmasını önerir.
    </h5>

    <h5 className="description">
    ZION Wind teknisyenleri, Rüzgar Türbin Kanatlarının garanti süresi dolmadan 6 ay önce kontrolü yapılmasını önerir. Garanti süresi dolmadan alınan Kanat Bakım ve Kontrol hizmeti, müşteriyi garanti sonrası bekleyen süreç ve yüksek maliyetten korur.
    </h5>

    <h5 className="description">
    ZION Wind teknisyenleri, Rüzgar Türbin Kanatlarının garanti süresi dolduktan sonra her 2 yıl da bir Kontrol yapılmasını önerir. Garanti Süresi dolmuş kanatlarda yapılan kontrol ve bakımın kayıt altına alınıp, gözlem altında tutulması gerekir.

    </h5>
  </Col>



</Row>

            </Container>
          </div>


          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default BladeServices;
