import React from "react";

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

import { Helmet } from 'react-helmet';
import i18n from "../../Util/i18n";
import languages from "../../Translations";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import ContactPageHeader from "components/Headers/ContactPageHeader";
import FooterSection from "views/index-sections/FooterSection";
import Contact from "views/index-sections/Contact";

function ContactUs() {
  const [pills, setPills] = React.useState("2");
    // use useTranslation hook
    const navigation = useNavigate()
    const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
       <Helmet>
        <meta lang={i18n.language} />
        <title>{t("seo_contact_us.seo_title")}</title>
        <meta name="description" content={t("seo_contact_us.seo_description")} />
      </Helmet>

      <IndexNavbar />
      <div className="wrapper">
        <ContactPageHeader />
        <div className="section">
          <Container>
          <Row className="justify-content-md-center">

        <h1 style={{margin:0, textAlign:'center'}}>CONTACT US</h1>
            <div className="space-50"></div>
          </Row>
          <p style={{textAlign:'center'}}>Lütfen formu eksiksiz doldurunuz.</p>

          <div className="space-50"></div>

            <Row>

              <Col>
                <Contact />

              </Col>
              <Col>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3124.267823441968!2d27.174702315224444!3d38.45837778030712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b962ab1b92b671%3A0x474c0172a7058959!2zQWRhbGV0LCAyMTMyLzMuIFNrLiA0L2EsIDM1NTMwIEJheXJha2zEsS_EsHptaXI!5e0!3m2!1sen!2str!4v1678530835573!5m2!1sen!2str" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

              </Col>

            </Row>
          </Container>
        </div>
        <FooterSection />
        <DefaultFooter />
      </div>
    </>
  );
}

export default ContactUs;
