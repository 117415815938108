const _trTranslation = {
  "lang_code": "TR",
  "Home": {
    "1": "Ana Sayfa",
    "url": "home"
  },
  "ZionWind": {
    "1": "Zion Rüzgar",
    "1a": "RES | Kanat Hizmetleri",
    "1b": "RES | Kule Hizmetleri",
    "url": "zion-ruzgar",
  },
  "ZionIndustrial": {
    "1": "Endüstriyel",
    "1a": "Faaliyet Alanlarımız",
    "1b": "Geoteknik Çözümlerimiz",
    "1c": "Korozyon Tamiri ve Boya Hizmetleri",
    "1d": "NDT Tahribatsız Muayene",
    "1e": "Cam & Cephe Temizliği",
    "1f": "Kapalı ve Kısıtlı Alanlarda Çalışma",
    "url": "iple-erisim-hizmetleri"
  },
  "ZionTraining": {
    "1": "Eğitim",
    "url": "zion-egitim"
  },
  "Contact": {
    "1": "İletişim",
    "url": "bize-ulasin"
  },

  "IndexSecondSection": {
    "1": "ZION : 'Dünya çapında erişim çözümleri.'",
    "description1": " ZİON İPLE ERİŞİM olarak yurt içi ve yurtdışında bulunan müşterilerimiz için İple Erişim Hizmetleri sunmaktan gurur duyuyoruz.",
    "description2": "ZİON İPLE ERİŞİM, yurt içi ve yurtdışında yüksek eğim, yükseklik ve boşluk bulunan erişim ve çalışma zorluğu olan her türlü yapıda genellikle iple erişim yöntemi kullanılarak sunulan çok çeşitli hizmetleri saha gerekliliklerine, müşteri şart ve isteklerine uygun olarak sunar.",
    "description3": "Hizmetlerini kendi ekipleri ile, müşteri ekiplerini yönetip yönlendirerek, danışmanlık sağlayarak ve projelendirerek gerçekleştirebilmektedir."
  },

  "IndexThirdSection": {
    "title_short": "Zion Endüstriyel",
    "title": "BAŞLICA HİZMETLERİMİZ",
    "description1": "İple Erişim Metodu - veya Rope Access (RA),",
    "description2": "Yüksekte çalışma gerektiren projeler için en güvenli, verimli ve uygun maliyetli yöntemdir.",
    "button": "Daha Fazla",

    "service1_title": "NDT",
    "service1a_title": "Tahribatsız Muayene",
    "service1_description1": "ZİON İPLE ERİŞİM olarak,",
    "service1_description2": "Tahribatsız Muayene Hizmetlerini, kapsamlı...",
    "service1Url": "ndt-tahribatsiz-muayene",


    "service2_title": "Şev / Yamaç Stabilizasyonu",
    "service2a_title": "ve Temizliği",
    "service2_description": "Uzman ZION teknisyenlerimiz henüz ilk incelemede en uygun stabilizasyon yöntemini,...",
    "service2Url": "sev-yamac-stabilizasyonu-ve-temizligi",

    "service3_title": "Yüksek Yapılar Cam",
    "service3a_title": "ve Cephe Temizliği",
    "service3_description": "İple erişim (endüstriyel dağcılık) yöntemiyle yapılacak cam temizliği diğer yöntemlere...",
    "service3Url": "yuksek-yapilar-cam-ve-cephe-temizligi",
  },

  "TeklifIste": {
    "1": "Get Offer",
    "url": "get-offer"
  },
  "NotFound": {
    "1": "404 Not Found",
    "description": "The page you are trying to reach may be invalid or removed !"
  },
  "Gonder": {
    "1": "Send"
  },

  //Newsletter Start
  "Newsletter": {
    "title": "BÜLTEN",
    "description": "Yeniliklerimizden haberdar olmak için bültenimize katılabilirsiniz.",
    "button": "Katıl",
    "mail_placeholder": "Email Adresiniz..."
  },
  //Newsletter End



  //Footer Start
  "FooterLinks_Col2": {
    "title": "Zion Rüzgar",
    "description": "Hizmetlerimiz",
    "blade_service": "Res | Kanat Hizmetlerimiz",
    "tower_service": "Res | Kule Hizmetlerimiz"
  },
  "FooterLinks_Col3": {
    "title": "Zion Endüstriyel",
    "description": "Hizmetlerimiz",
    "1": "NDT Tahribatsız Muayene",
    "2": "Şev / Yamaç Stabilizasyonu ve Temizliği",
    "3": "Cam ve Cephe Temizliği",
    "4": "Kapalı ve Kısıtlı Alanlarda Çalışma"
  },
  //Footer End


  // Index Slider Start
  "Slider": {
    "description": "ZİON İPLE ERİŞİM olarak yurt içi ve yurtdışında bulunan müşterilerimiz için İple Erişim Hizmetleri sunmaktan gurur duyuyoruz.",
    "button": "BİZE ULAŞIN",
    "1": "ZION İPLE ERİŞİM",
    "2": "Geoteknik Çözümlerimiz",
    "3": "Korozyon Tamiri ve Boya Hizmetleri",
    "4": "NDT Tahribatsız Muayene",
    "5": "Şev / Yamaç Stabilizasyonu ve Temizliği",
    "6": "Cam & Cephe Temizliği",
    "7": "Kapalı ve Kısıtlı Alanlarda Çalışma"
  },
  // Index Slider End


  // ZION Worl Start
  "ZionWorld": {
    "title": "ZION DÜNYASI",
    "description": "Dünyamızı keşfedin.",
    "image_Title1": "ZION DÜNYASI",
    "image_Title2": "ZION ENDÜSTRİYEL",
    "button": "Daha Fazla",
  },
  // ZION Worl End

  // Gallery Start
  "Gallery": {
    "title": "GALERİ",
    "description": "Çözümler için yeni zirvelere ulaşıyoruz.",
  },

  // Gallery End
  "FaaliyetAlanlari": {
    "title": "FAALİYET ALANLARIMIZ",
    "description": "ZION IPLE ERİŞİM HİZMETLERİ",
    "1": "PETROL, GAZ, PETROKİMYA",
    "2": "TERMİK ENERJİ SANTRALLERİ",
    "3": "JEOTERMAL ENERJİ SANTRALLERİ",
    "4": "YAMAÇLAR"
  }
}

export default _trTranslation
