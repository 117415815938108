import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ImageGallery from 'react-image-gallery';
import { ReactPhotoCollage } from "react-photo-collage";
import { useTranslation } from 'react-i18next';



function ImageGallerySection() {
  const { t } = useTranslation();


  const setting = {
    width: "100%",
    height: ["250px", "170px"],
    layout: [3, 4, 5],
    photos: [
      { source: require('assets/img/gallery/016.jpg') },
      { source: require('assets/img/gallery/017.jpg') },
      { source: require('assets/img/gallery/015.jpg') },
      { source: require('assets/img/gallery/010.png') },
      { source: require('assets/img/gallery/011.png') },
      { source: require('assets/img/gallery/012.jpg') },
      { source: require('assets/img/gallery/049.jpg') },
      { source: require('assets/img/gallery/075.jpg') },
      { source: require('assets/img/gallery/061.jpg') },
      { source: require('assets/img/gallery/060.jpg') },
      { source: require('assets/img/gallery/074.jpg') },
      { source: require('assets/img/gallery/01.jpg') },
      { source: require('assets/img/gallery/03.jpg') },
      { source: require('assets/img/gallery/062.jpg') },
      { source: require('assets/img/gallery/063.jpg') },
      { source: require('assets/img/gallery/02.jpg') },
      { source: require('assets/img/gallery/073.jpg') },
      { source: require('assets/img/gallery/072.jpg') },
      { source: require('assets/img/gallery/066.jpg') },
      { source: require('assets/img/gallery/07.jpg') },
      { source: require('assets/img/gallery/05.jpg') },
      { source: require('assets/img/gallery/070.jpg') },
      { source: require('assets/img/gallery/064.jpg') },
      { source: require('assets/img/gallery/058.jpg') },
      { source: require('assets/img/gallery/059.jpg') },
      { source: require('assets/img/gallery/04.jpg') },
      { source: require('assets/img/gallery/09.jpg') },
      { source: require('assets/img/gallery/054.jpg') },
      { source: require('assets/img/gallery/055.jpg') },
      { source: require('assets/img/gallery/057.jpg') },
      { source: require('assets/img/gallery/056.jpg') },
      { source: require('assets/img/gallery/052.jpg') },
      { source: require('assets/img/gallery/051.jpg') },
      { source: require('assets/img/gallery/045.jpg') },
      { source: require('assets/img/gallery/050.jpg') },
      { source: require('assets/img/gallery/018.jpg') },
      { source: require('assets/img/gallery/026.jpg') },
      { source: require('assets/img/gallery/033.jpg') }
    ].sort(() => Math.random() - 0.5),
    showNumOfRemainingPhotos: true
  };

  return (
    <>
      <div className="section">

        <Container className="text-left">
          <Row className="justify-content-md-center">
            <h2 style={{margin:0}}>{t("Gallery.title")}</h2>
            <div className="space-50"></div>
          </Row>
          <p style={{textAlign:'center'}}>{t("Gallery.description")}</p>

          <div className="space-50"></div>
          <Row className="justify-content-md-center">
            {/*<ImageGallery items={images} />*/}
            <ReactPhotoCollage className="img-raised" {...setting} />
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ImageGallerySection;
