/*eslint-disable*/
import React, { useContext, useState } from 'react';

// reactstrap components
import {
  Container, Row, Col, Button,
} from "reactstrap";
// core components
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import WidioneLogo from 'components/Logo';
import { TypeAnimation } from 'react-type-animation';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function ZionWindHeader() {
  let pageHeader = React.createRef();
  const { t } = useTranslation();
  const [backgroundImage, setbackgroundImage] = useState('zion-index-slider-1.jpg')

  const [content, setcontent] = useState([{
    tr: [{
      image: 'web-development.png',
      title: 'Kurumsal Web Sitesi',
      description: 'Özgün tasarımlarımızla etkileyici bir siteniz olmasını ister misiniz ?',
      link: 'kurumsal-web-sitesi'
    },
    {
      image: 'web-app.png',
      title: 'Özel Uygulamalar',
      description: 'Özgün tasarımlarımızla etkileyici bir siteniz olmasını ister misiniz ?',
      link: 'ozel-uygulamalar'
    },
    ],
    en: [{
      image: 'web-development.png',
      title: 'Business Website',
      description: 'Would you like to have an impressive site with our original designs?',
      link: 'business-website'
    }],
    de: [{
      image: 'web-development.png',
      title: 'Firmen Web Site',
      description: 'Möchten Sie eine beeindruckende Website mit unseren originellen Designs haben?',
      link: 'firmen-web-site'
    }]
  }]);


  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div className="page-header  clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            transition: "background 0.3s linear",
            backgroundImage: "url(" + require(`assets/img/slider/${backgroundImage}`) + ")"
          }}
          ref={pageHeader}
        >
           <video loop autoPlay muted id="bg-video">
          <source src={require('assets/video/zion-wind.mov')} type="video/mp4"></source>
        </video>
        </div>
        <Container>




          <div className="content-center brand">

          <div>
            <TypeAnimation

              // Same String at the start will only be typed once, initially
              sequence={[
                () => {
                  console.log('Done 1!');
                  setbackgroundImage('zion-index-slider-1.jpg')
                },
                'ZION WIND',
                5000,

              ]}
              speed={70} // Custom Speed from 1-99 - Default Speed: 40

              wrapper="h1" // Animation will be rendered as a <span>
              repeat={Infinity} // Repeat this Animation Sequence infinitely
            />
            </div>

            <h3>RÜZGAR TÜRBİNLERİ HİZMETLERİ</h3>
            <h4>Uzman ekibimiz, onarım işleminizin etkili ve verimli bir şekilde tamamlanacağını garanti eder. İşimizi doğru yapmak için gerekli deneyime ve özveriye sahibiz. Sizin için neler yapabileceğimizi görmek için hemen bize ulaşın.</h4>
            <Button
             href={"/" + i18next.language + "/" + t('Contact.url')}
             color='danger'>CONTACT US</Button>
          </div>

          <h6 className="category category-absolute">
            Made by {" "}
            <a href="http://widione.com" target="_blank">
              <WidioneLogo style={{ width: "5%" }} />

            </a>

          </h6>


        </Container>
      </div>
    </>
  );
}

export default ZionWindHeader;
