import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../../components/Headers/IndexHeader.js";
import DarkFooter from "../../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../../components/Footers/DefaultFooter.js";

// sections for this page
import FooterSection from "../../index-sections/FooterSection.js";
import ImageGallerySection from "../../index-sections/ImageGallerySection.js";

import { Container, Row, Col } from "reactstrap";
import ZionWorld from "../../index-sections/ZionWorld.js";
import { useTranslation } from 'react-i18next';
import MainServices from "../../index-sections/MainServices.js";
import GeotechnicalSolutionHeader from "components/Headers/GeotechnicalSolutionHeader.js";
import CorrosionRepairHeader from "components/Headers/CorrosionRepairHeader.js";
import NtdHeader from "components/Headers/NtdHeader.js";
import WorkingAtClosedHeader from "components/Headers/WorkingAtClosedHeader.js";

function WorkingAtClosed() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <WorkingAtClosedHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />

          */ }
          <div className="section">
            <Container className="text-left">

              <Row className="justify-content-md-center">

                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/work-at-close-2.webp")}
                  ></img>
                </Col>

                <Col lg="5" md="12">
                  <h5 className="description">
                  Kapalı ve Kısıtlı Alanlar; sürekli çalışma yeri olarak tasarlanmamış, giriş ve çıkış yolları sınırlı, tamamen veya kısmen kapalı, sınırlı hacme sahip, içinde rahat hareket edilemeyen, doğal bir hava akımı olmayan veya içerisinde sınırlı miktarda hava olan alanlardır. Bu alanlarda oksijen seviyesindeki düşüklük, ortamdaki yanıcı/ patlayıcı  sıvı, katı, gaz ve tozlar, mekanik tehlikeler ile fiziksel koşullar gibi nedendelerden dolayı çalışmak oldukça zor ve tehlikelidir.
                  </h5>
                </Col>
              </Row>

              <div className="space-50"></div>
            </Container>
          </div>
          <MainServices />
          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default WorkingAtClosed;
