import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../components/Headers/IndexHeader.js";
import DarkFooter from "../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";

// sections for this page
import Images from "../index-sections/Images.js";
import BasicElements from "../index-sections/BasicElements.js";
import Navbars from "../index-sections/Navbars.js";
import Tabs from "../index-sections/Tabs.js";
import Pagination from "../index-sections/Pagination.js";
import Notifications from "../index-sections/Notifications.js";
import Typography from "../index-sections/Typography.js";
import Javascript from "../index-sections/Javascript.js";
import Carousel from "../index-sections/Carousel.js";
import NucleoIcons from "../index-sections/NucleoIcons.js";
import CompleteExamples from "../index-sections/IndexSecondSection.js";
import SignUp from "../index-sections/SignUp.js";
import Examples from "../index-sections/MainServices.js";
import Download from "../index-sections/Download.js";
import FooterSection from "../index-sections/FooterSection.js";
import ImageGallerySection from "../index-sections/ImageGallerySection.js";
import Contact from "../index-sections/Contact.js";

import ZionWindHeader from "components/Headers/Zion-Wind-Header.js";
import { Container, Row, Col } from "reactstrap";
import ZionWindHizmetler from "views/index-sections/ZionWind-Hizmetler.js";
import ZionWorld from "views/index-sections/ZionWorld.js";
import ZionWindBladeServicesHeader from "components/Headers/ZionWindBladeServicesHeader.js";
import ZionIndustrialHeader from "components/Headers/ZionIndustrialHeader.js";
import { useTranslation } from 'react-i18next';
import MainServices from "../index-sections/MainServices.js";
import OtherServices from "views/index-sections/OtherServices.js";

function ZionIndustrial() {
  const { t } = useTranslation();


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ZionIndustrialHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">


            <Container className="text-left">
              <Row className="justify-content-md-center">
                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/zion-industrial.webp")}
                  ></img>
                </Col>

                <Col lg="8" md="12">
                  <h2 className="title">ZION ENDÜSTRİYEL</h2>
                  <h5 className="description">
                    İple Erişim Metodu - veya Rope Access (RA) - , yüksekte çalışma gerektiren projeler için en güvenli, verimli ve uygun maliyetli yöntemdir.
                    Bunun nedeni, genellikle kısa bir teslim süresinin elde edilebilmesidir.
                  </h5>

                  <h5 className="description">
                    İple Erişim, birden fazla disiplini birleştirerek paralel faaliyetler ve süreçler üzerinde minimum etkiye sahiptir.
                    Yıllar içinde, iple erişim güvenliği endüstride önemli ölçüde gelişmiştir.
                  </h5>


                  <h5 className="description">
                    Sadece çalışma yöntemleri için değil, aynı zamanda personel ve kullanılacak malzemeler için de güvenlik ve kalite için çeşitli uluslararası standartlar ve yönetmelikler geliştirilmiştir.
                  </h5>

                  <h5 className="description">
                    Başlangıçta teknikler dağcılık ve mağaracılıktan geliyordu, ancak bugün bu sporlarla neredeyse hiç benzerlik yok. İple erişim teknikleri son 20 yılda endüstride kullanılmak üzere önemli ölçüde uyarlanmış ve geliştirilmiştir.
                  </h5>


                  <h5 className="description">
                    İple erişim, iş istasyonlarını geçici olarak konumlandırmanın özel bir yöntemidir. Bu teknik, "hatların" birincil aksesuar olarak kullanıldığı durumlar ve bir inşaat sahasına veya inşaat alanından dikey ve/veya yatay taşıma için geçerlidir.
                  </h5>


                  <h5 className="description">
                    Bu sayede profesyonellere detaylı bir brifing sonrasında iple erişim konusundaki çalışmaları öğretilebilir.
                  </h5>

                </Col>

              </Row>

              <div className="space-50"></div>

            </Container>
          </div>
          <MainServices/>

          <ImageGallerySection />
          <div className="space-50"></div>

          <div className="space-50"></div>

          <ZionWorld />
          <FooterSection />

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ZionIndustrial;
