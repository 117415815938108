import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// core components

function MainServices() {
  const [showImage1, setShowImage1] = useState(false);
  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);
  const { t } = useTranslation();


  return (
    <>
      <div className="section section-examples" data-background-color="black">

        <Container className="text-center">
          <Row>
            <Col>
              <h5 className="text-center"> {t("IndexThirdSection.title_short")} </h5>
              <h1 className="text-center">{t("IndexThirdSection.title")}</h1>
              <p>{t("IndexThirdSection.description1")} <br /> {t("IndexThirdSection.description2")}</p>
              <div className="space-50"></div>

            </Col>
          </Row>

          <Row>
            <Col lg="4" md="12" onMouseEnter={() => setShowImage1(true)}
              onMouseLeave={() => setShowImage1(false)}>
              <a href="#">
                <img

                  style={{ opacity: showImage1 ? 0.6 : 1 }}
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/services/non-destructive-testing.webp")}
                ></img>
              </a>
              <h5 style={{ margin: 20 }}>{t("IndexThirdSection.service1_title")} <br /> {t("IndexThirdSection.service1a_title")}</h5>
              <p>{t("IndexThirdSection.service1_description1")} <br /> {t("IndexThirdSection.service1_description2")}</p>
              <Button
                className="btn-round"
                color="default"

                to={`/${i18next.language}/ndt-tahribatsiz-muayene`}


                outline
                tag={Link}
              >
                {t("IndexThirdSection.button")}
              </Button>


            </Col>

            <Col lg="4" md="12" onMouseEnter={() => setShowImage2(true)}
              onMouseLeave={() => setShowImage2(false)}>
              <a href="#">
                <img
                  style={{ opacity: showImage2 ? 0.6 : 1 }}

                  alt="..."
                  className="img-raised"
                  src={require("assets/img/services/slope-stabilization-and-cleaning.webp")}
                ></img>
              </a>
              <h5 style={{ margin: 20 }}>{t("IndexThirdSection.service2_title")} <br /> {t("IndexThirdSection.service2a_title")}</h5>
              <p>{t("IndexThirdSection.service2_description")}</p>
              <Button
                className="btn-round"
                color="default"
                to={`/${i18next.language}/sev-yamac-stabilizasyonu-ve-temizligi`}
                outline
                tag={Link}
              >
                {t("IndexThirdSection.button")}

              </Button>
            </Col>

            <Col lg="4" md="12" onMouseEnter={() => setShowImage3(true)}
              onMouseLeave={() => setShowImage3(false)}>
              <a href="#">
                <img
                  style={{ opacity: showImage3 ? 0.6 : 1 }}
                  alt="..."
                  className="img-raised"
                  src={require("assets/img/services/facede-cleaning.webp")}
                ></img>
              </a>
              <h5 style={{ margin: 20 }}>{t("IndexThirdSection.service3_title")}<br /> {t("IndexThirdSection.service3a_title")}</h5>
              <p>{t("IndexThirdSection.service3_description")}</p>
              <Button
                className="btn-round"
                color="default"
                to={`/${i18next.language}/cam-ve-dis-cephe-bakim-onarim-ve-temizligi`}
                outline
                tag={Link}
              >
                {t("IndexThirdSection.button")}
              </Button>
            </Col>





          </Row>
        </Container>
      </div>
    </>
  );
}

export default MainServices;
