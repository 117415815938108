import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const fallbackLng = ['tr'];
const availableLanguages = ['tr', 'en'];


const options = {
  // order and from where user language should be detected
  order: [ 'querystring', 'cookie', 'localstorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'localhost',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

// Let's get the user's language
const getLanguage = () => {
  const ls = localStorage.getItem("language")
  // Let's check if there is a value containing the language key in localStorage.
  if (ls !== null) {
      // If there is a value containing language key in localstorage

      // Is the language value one of the languages that will be included in our application?
      if (Object.keys(availableLanguages).find(f => f === ls) !== undefined) {
          // return this if it is one of the languages included in the application
          return ls
      } else {
          // If it is a language that is not included in the application, use the English language by default
          localStorage.setItem("language", "tr")
          return "tr"
      }
  } else {
      // If the user's language is not saved in localstorage
      // Get browser language
      let parsed = navigator.language
      if (parsed.includes("-")) {
          parsed = parsed.split("-")[0]
      }
      localStorage.setItem("language", parsed)
      return parsed
  }
}


i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(initReactI18next) // pass the i18n instance to react-i18next.



  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: true,
    whitelist: availableLanguages,
    detection: options,

    interpolation: {
      escapeValue: false
    },
  });

export default i18n;