import React, { Component, createRef, useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components

import ReCAPTCHA from "react-google-recaptcha";
import { Formik } from "formik";
import * as yup from "yup";
import { postContact } from "functions/global";
import Swal from 'sweetalert2';
import i18next from 'i18next';

const TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const WIDIONE_KEY = "6LdkJMUjAAAAANiFm1pv78CXSPWns8oSEUZnwomm";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


export default class Contact extends Component {


  constructor(props) {
    super(props);

    this._validationSchema = yup
      .object()
      .shape({
        recaptcha: yup.string().required(),
        tel: yup.string()
          .matches(/^[0-9]+$/, "Telefon numarası rakamlardan oluşmalıdır.")
          .min(11, "Telefon numarası çok kısa.")
          .max(11, "Telefon numarası çok uzun.")
          .required('Telefon numarası zorunlu alandır.'),

        mail: yup.string()
          .email('Geçersiz eposta adresi girdiniz.')
          .required('E-Posta adresi zorunlu alandır.'),

        address: yup.string()
          .required('Adres zorunlu alandır.'),

        subject: yup.string()
          .required('Konu zorunlu alandır.'),

        message: yup.string()
          .required('Mesaj zorunlu alandır.'),
      });
    this._initialValues = { recaptcha: "", tel: "", mail: "", address: "", subject: "", message: "" };
    this._reCaptchaRef = createRef();
  }


  render() {
    return (
      <>
        <Formik
          validationSchema={this._validationSchema}
          initialValues={this._initialValues}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, handleChange, touched, errors, values }) => (
            <form onSubmit={handleSubmit}>

              <Row>
                <Col className="text-center ml-auto mr-auto" lg="10" md="12">
                  <InputGroup className={"input-lg" + (touched.tel && !errors.tel ? " has-success" : " has-danger")}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-success"
                      ref={input => this.tel = input}
                      value={values.tel}
                      placeholder="Telefon numaranız..."
                      type="text"
                      onChange={handleChange('tel')}
                      onBlur={() => setFieldTouched('tel')}
                    >
                    </Input>
                  </InputGroup>
                  {touched.tel && errors.tel &&
                    <p style={{ color: '#FF0D10' }}>{errors.tel}</p>
                  }

                  <InputGroup className={"input-lg" + (touched.mail && !errors.mail ? " has-success" : " has-danger")}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons ui-1_email-85"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-success"
                      ref={input => this.mail = input}
                      value={values.mail}
                      placeholder="Mail adresiniz..."
                      type="text"
                      onChange={handleChange('mail')}
                      onBlur={() => setFieldTouched('mail')}
                    >
                    </Input>
                  </InputGroup>
                  {touched.mail && errors.mail &&
                    <p style={{ color: '#FF0D10' }}>{errors.mail}</p>
                  }

                  <InputGroup className={"input-lg" + (touched.address && !errors.address ? " has-success" : " has-danger")}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons location_pin"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-success"
                      ref={input => this.address = input}
                      value={values.address}
                      placeholder=" İl / İlçe..."
                      type="text"
                      onChange={handleChange('address')}
                      onBlur={() => setFieldTouched('address')}
                    >
                    </Input>
                  </InputGroup>
                  {touched.address && errors.address &&
                    <p style={{ color: '#FF0D10' }}>{errors.address}</p>
                  }

                  <InputGroup className={"input-lg" + (touched.subject && !errors.subject ? " has-success" : " has-danger")}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="now-ui-icons location_pin"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-success"
                      ref={input => this.subject = input}
                      value={values.subject}
                      placeholder="Konu Başlığı..."
                      type="text"
                      onChange={handleChange('subject')}
                      onBlur={() => setFieldTouched('subject')}
                    >
                    </Input>
                  </InputGroup>
                  {touched.subject && errors.subject &&
                    <p style={{ color: '#FF0D10' }}>{errors.subject}</p>
                  }

                  <div className="textarea-container">
                    <InputGroup className={"input-lg" + (touched.message && !errors.message ? " has-success" : " has-danger")}>
                      <Input
                        ref={input => this.message = input}
                        //cols="120"
                        placeholder="Mesajınız..."
                        //rows="10"
                        type="textarea"
                        onChange={handleChange('message')}
                        onBlur={() => setFieldTouched('message')}
                      ></Input>
                    </InputGroup>
                    {touched.message && errors.message &&
                      <p style={{ color: '#FF0D10' }}>{errors.message}</p>
                    }
                  </div>

                  <div className="send-button">
                    <ReCAPTCHA
                      ref={this._reCaptchaRef}
                      sitekey={TEST_SITE_KEY}
                      onChange={(value) => setFieldValue("recaptcha", value)}
                      size="normal"
                    />

                  </div>

                  <Button
                    block
                    className="btn-round"
                    color="danger"
                    type="submit"
                    size="lg"
                  >
                    <span>{i18next.t('Gonder.1')}</span>
                  </Button>

                </Col>
              </Row>


            </form>
          )}
        </Formik>

      </>
    );
  }
}