import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

// sections for this page
import Images from "./index-sections/Images.js";
import BasicElements from "./index-sections/BasicElements.js";
import Navbars from "./index-sections/Navbars.js";
import Tabs from "./index-sections/Tabs.js";
import Pagination from "./index-sections/Pagination.js";
import Notifications from "./index-sections/Notifications.js";
import Typography from "./index-sections/Typography.js";
import Javascript from "./index-sections/Javascript.js";
import Carousel from "./index-sections/Carousel.js";
import NucleoIcons from "./index-sections/NucleoIcons.js";
import SignUp from "./index-sections/SignUp.js";
import Download from "./index-sections/Download.js";
import FooterSection from "./index-sections/FooterSection.js";
import ImageGallerySection from "./index-sections/ImageGallerySection.js";
import Contact from "./index-sections/Contact.js";

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import IndexSecondSection from "./index-sections/IndexSecondSection.js";
import MainServices from "./index-sections/MainServices.js";

function Index() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
        <IndexNavbar />
        <div className="wrapper">
          <IndexHeader />
          <div className="main">
            {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }


          <IndexSecondSection />
          <MainServices />
          <ImageGallerySection/>
          <FooterSection/>

          </div>
          <DefaultFooter />
        </div>
    </>
  );
}

export default Index;
