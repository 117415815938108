import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import languages from "../../Translations";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import WidioneLogo from "../Logo";
import ZionLogo from "../LogoZion";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ZionLogoWhite from "../LogoZionWhite";
import i18n from "../../Util/i18n";


function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  // use useTranslation hook
  const navigation = useNavigate()
  const { t } = useTranslation();


  function renderLanguageChangeButtons() {
    return Object.keys(languages).map((items, index) =>
      <NavItem key={index}>
        <NavLink
          href="#"
          disabled={i18n.language === items}
          onClick={async () => {
            await i18n.changeLanguage(items)
            navigation(`/${items}`)
          }}
          style={{ margin: 5 }}>
          <strong>         {items}
          </strong>
        </NavLink>
      </NavItem>

    );
  }


  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (

    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="danger">
        <Container>


          <div className="navbar-translate">
            <NavbarBrand
              href={"/" + i18next.language + "/" + t('Home.url')}
              id="navbar-brand"
            >

              {navbarColor == 'navbar-transparent' ? <ZionLogo style={{ width: "130px" }} /> : <ZionLogoWhite style={{ width: "130px" }} />}


            </NavbarBrand>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href={"/" + i18next.language + "/" + t('Home.url')}
                >
                  <i className="now-ui-icons objects_globe"></i>
                  <p>{t('Home.1')}</p>
                </NavLink>
              </NavItem>


              <UncontrolledDropdown nav>

                <DropdownToggle
                  caret
                  color="default"
                  onClick={async () => {
                    navigation(`/${i18next.language}/zion-wind`)
                  }}
                  nav
                >
                  <i className="now-ui-icons design_app mr-1"></i>
                  <p>{t('ZionWind.1')}</p>
                </DropdownToggle>

                <DropdownMenu>


                  <DropdownItem to={`/${i18next.language}/res-kanat-hizmetlerimiz`} tag={Link}>
                    {t('ZionWind.1a')}
                  </DropdownItem>
                  <DropdownItem
                    to={`/${i18next.language}/res-kule-hizmetlerimiz`} tag={Link}
                  >
                    {t('ZionWind.1b')}
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>


              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="#"
                  nav
                  onClick={async () => {
                    navigation(`/${i18next.language}/iple-erisim-hizmetleri`)
                  }}
                >
                  <i className="now-ui-icons ui-2_settings-90"></i>
                  <p>{t('ZionIndustrial.1')}</p>
                </DropdownToggle>

                <DropdownMenu>
                  <DropdownItem to={`/${i18next.language}/faaliyet-alanlarimiz`} tag={Link}>
                    {t('ZionIndustrial.1a')}
                  </DropdownItem>

                  <DropdownItem to={`/${i18next.language}/sev-yamac-stabilizasyonu-ve-temizligi`} tag={Link}>
                    {t('ZionIndustrial.1b')}
                  </DropdownItem>
                  <DropdownItem to={`/${i18next.language}/korozyon-tamiri-ve-boya-hizmetlerimiz`} tag={Link}>
                    {t('ZionIndustrial.1c')}
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    to={`/${i18next.language}/ndt-tahribatsiz-muayene`} tag={Link}
                  >
                    {t('ZionIndustrial.1d')}
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    to={`/${i18next.language}/cam-ve-dis-cephe-bakim-onarim-ve-temizligi`} tag={Link}
                  >
                    {t('ZionIndustrial.1e')}
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    to={`/${i18next.language}/kapali-ve-kisitli-alanlarda-calisma`} tag={Link}
                  >
                    {t('ZionIndustrial.1f')}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink
                  href={`/${i18next.language}/${t('ZionTraining.url')}`}
                >
                  <i className="now-ui-icons travel_info"></i>
                  <p>{t('ZionTraining.1')}</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href={"/" + i18next.language + "/" + t('Contact.url')}

                >
                  <i className="now-ui-icons business_briefcase-24"></i>
                  <p> {t('Contact.1')}</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/company/zion-rope-access/"
                  id="linkedin-tooltip"
                >
                  <i className="fab fa-linkedin"></i>
                  <p className="d-lg-none d-xl-none">Linkedin</p>
                </NavLink>

              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.facebook.com/people/Zion-Rope-Access/100086246922579/"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>

              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/zionipleerisim/"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>

              </NavItem>
              {renderLanguageChangeButtons()}

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;


