import translations from "../Translations";
import {Route, Routes, Navigate, useLocation} from "react-router-dom";
import { useEffect} from "react";
import {useTranslation} from "react-i18next";
import ZionWind from "../views/Pages/Zion-Wind";
import ContactUs from "views/Pages/ContactUs";
import Index from "views/Index";
import BladeServices from "views/Pages/BladeServices";
import TowerServices from "views/Pages/TowerServices";
import ServicesPage from "views/Pages/ServicesPage";
import AboutUs from "views/Pages/AboutUs";
import ZionTraining from "views/Pages/ZionTraining";
import ActivityAreas from "views/Pages/ActivityAreas";
import ZionIndustrial from "views/Pages/ZionIndustrial";
import GeoTechnicalSolutions from "views/Pages/IndustrialServices/GeoTechnicalSolutions";
import CorrosionRepair from "views/Pages/IndustrialServices/CorrosionRepair";
import Ntd from "views/Pages/IndustrialServices/Ntd";
import GlassFacedeCleaning from "views/Pages/IndustrialServices/GlassFacedeCleaning";
import WorkingAtClosed from "views/Pages/IndustrialServices/WorkingAtClosed";

function RoutesByLanguage(){
    // Translation hook
    const { i18n} = useTranslation()
    // React Router location hook
    const loc = useLocation()
    useEffect(() => {
        // parse language code from url. ex: test.com/tr/home -> result: tr
        const lang = loc.pathname.split(/\/([a-z]{2})(?![^\/])/gm)[1]
        // check lang index
        const i = Object.keys(translations).findIndex(f => f === lang)
        // if language different than known language
        if( i !== -1 && i18n.language !== lang) {
            // change language
            i18n.changeLanguage(lang)
        }
    }, [loc, i18n])
    const routes = () => {
        return(
            <>
                {/* test.com/tr -> test.com/tr/home */ }
                <Route index element={<Navigate to={`/${i18n.language}/home`} />} />

                <Route path={"home"} element={<Index />} />
                <Route path="zion-ruzgar" element={<ZionWind/>} />
                <Route path="zion-wind" element={<ZionWind/>} />
                <Route path="contact-us" element={<ContactUs/>} />
                <Route path="bize-ulasin" element={<ContactUs/>} />
                <Route path="res-kanat-hizmetlerimiz" element={<BladeServices/>} />
                <Route path="res-kule-hizmetlerimiz" element={<TowerServices/>} />
                <Route path="zion-egitim" element={<ZionTraining/>} />
                <Route path="faaliyet-alanlarimiz" element={<ActivityAreas/>} />
                <Route path="iple-erisim-hizmetleri" element={<ZionIndustrial/>} />
                <Route path="sev-yamac-stabilizasyonu-ve-temizligi" element={<GeoTechnicalSolutions/>} />
                <Route path="korozyon-tamiri-ve-boya-hizmetlerimiz" element={<CorrosionRepair/>} />
                <Route path="ndt-tahribatsiz-muayene" element={<Ntd/>} />
                <Route path="cam-ve-dis-cephe-bakim-onarim-ve-temizligi" element={<GlassFacedeCleaning/>} />
                <Route path="kapali-ve-kisitli-alanlarda-calisma" element={<WorkingAtClosed/>} />


            </>
        )
    }
    function renderRoutes(){
        return Object.keys(translations).map((lang_code, id) => {
            return (
                <>
                    {/* Generate url by language. Ex: test.com/en, test.com/en */}
                    <Route key={id} path={lang_code}>
                        {routes()}
                    </Route>
                </>
            );
        })
    }
    return (
        <Routes>
            {/* If the user visits test.com, redirect them to the appropriate page for their language. For example test.com -> test.com/en */}
            <Route path={"/"} element={<Navigate to={`${i18n.language}`} replace />} />
            {renderRoutes()}
        </Routes>
    )
}

export default RoutesByLanguage
