const _enTranslation = {
  "lang_code": "EN",
  "hello": "Hello",
  "Home": {
    "1": "Home",
    "url": "home"
  },
  "ZionWind": {
    "1": "Zion Wind",
    "1a": "Blade Services",
    "1b": "Tower Services",
    "url": "zion-wind"
  },
  "ZionIndustrial": {
    "1": "Industrial",
    "1a": "Our Activity Areas",
    "1b": "Geotechnical Solutions",
    "1c": "Corrosion Repair and Painting Services",
    "1d": "NDT Non-Destructive Testing",
    "1e": "Glass & Facade Cleaning",
    "1f": "Working in Closed and Restricted Areas",
    "url": "zion-industrial"
  },
  "ZionTraining": {
    "1": "Training",
    "url": "zion-training"
  },
  "Contact": {
    "1": "Contact",
    "url": "contact-us"
  },
  "IndexFirstSection": {
    "1": "Rope Access Services",
    "description": "As ZION ROPE ACCESS, we are proud to offer Rope Access Services for our customers in Turkey and abroad ."
  },
  "IndexSecondSection": {
    "1": "'Elevating access solutions, worldwide': ZION",
    "description1": "As ZION ROPE ACCESS, we are proud to offer Rope Access Services for our domestic and international customers.",
    "description2": "ZION ROPE ACCESS offers a wide range of services in accordance with the field requirements, customer conditions and requests, which are generally offered by using the rope access method in all kinds of structures with high slopes, heights and gaps in the country and abroad, which have difficulty in accessing and working.",
    "description3": "We perform our services with our own expertised technicians, by managing and directing customer specific workflows, providing consultancy and projecting"
  },

  "IndexThirdSection": {
    "title_short": "Zion Industrial",
    "title": "OUR MAIN SERVICES",
    "description1": "The Rope Access Method - or Rope Access (RA) - is the safest,",
    "description2": "most efficient and cost-effective method for projects that require work at height.",
    "button": "View More",

    "service1_title": "NDT",
    "service1a_title": "Non-Destructive Testing",
    "service1_description1": "As ZION ROPE ACCESS,",
    "service1_description2": "Non-Destructive Testing Services, comprehensive...",
    "service1Url": "ndt-non-destructive-testing",


    "service2_title": "Slope Stabilization",
    "service2a_title": "and Cleaning",
    "service2_description": "Our expert ZION technicians have yet to find the most suitable stabilization method at the first inspection,...",
    "service2Url": "sev-yamac-stabilizasyonu-ve-temizligi",

    "service3_title": "High Constructions Glass",
    "service3a_title": "& Facade Cleaning",
    "service3_description": "Window cleaning by rope access (industrial mountaineering) method is different from other methods.",
    "service3Url": "yuksek-yapilar-cam-ve-cephe-temizligi",
  },


  "TeklifIste": {
    "1": "Get Offer",
    "url": "get-offer"
  },
  "NotFound": {
    "1": "404 Not Found",
    "description": "The page you are trying to reach may be invalid or removed !"
  },
  "Gonder": {
    "1": "Send"
  },

  //Newsletter
  "Newsletter": {
    "title": "NEWSLETTER",
    "description": "Join our newsletter and get news in your inbox ! We hate spam too, so no worries about this.",
    "mail_placeholder": "Your Email...",
    "button": "Subscribe"
  },
  "FooterLinks_Col2": {
    "title": "Zion Wind",
    "description": "Provided Services",
    "blade_service": "Blade Services",
    "tower_service": "Tower Services"
  },
  "FooterLinks_Col3": {
    "title": "Zion Industrial",
    "description": "Provided Services",
    "1": "NDT Non-Destructive Testing",
    "2": "Slope Stabilization and Cleaning",
    "3": "Glass and Facade Cleaning",
    "4": "Working in Confined and Restricted Spaces"
  },

  // Index Slider Start
  "Slider": {
    "description": "As ZION ROPE ACCESS, we are proud to offer Rope Access Services for our customers in Turkey and abroad.",
    "button": "CONTACT US",
    "1": "ZION ROPE ACCESS",
    "2": "Geotechnical Solutions",
    "3": "Corrosion Repair and Painting Services",
    "4": "NDT Non-Destructive Testing",
    "5": "Slope Stabilization & Cleaning",
    "6": "Glass & Facade Cleaning",
    "7": "Working in Closed and Restricted Areas"
  },
  // Index Slider End

  // ZION Worl Start
  "ZionWorld": {
    "title": "ZION WORLD",
    "description": "Explore our world",
    "image_Title1": "ZION WORLD",
    "image_Title2": "ZION INDUSTRIAL",
    "button": "View More",
  },
  // ZION Worl End


  // Gallery Start
  "Gallery": {
    "title": "GALLERY",
    "description": "Reaching new heights for solutions.",
  },
  // Gallery End

  "FaaliyetAlanlari": {
    "title": "OUR ACTIVITY AREAS",
    "description": "ZION ROPE ACCESS",
    "1": "OIL, GAS, PETROCHEMICAL",
    "2": "THERMAL POWER PLANTS",
    "3": "GEOTHERMAL POWER PLANTS",
    "4": "SKILLS"
  }

}

export default _enTranslation
