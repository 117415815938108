import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../../components/Headers/IndexHeader.js";
import DarkFooter from "../../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../../components/Footers/DefaultFooter.js";

// sections for this page
import FooterSection from "../../index-sections/FooterSection.js";
import ImageGallerySection from "../../index-sections/ImageGallerySection.js";

import { Container, Row, Col } from "reactstrap";
import ZionWorld from "../../index-sections/ZionWorld.js";
import { useTranslation } from 'react-i18next';
import MainServices from "../../index-sections/MainServices.js";
import GeotechnicalSolutionHeader from "components/Headers/GeotechnicalSolutionHeader.js";
import CorrosionRepairHeader from "components/Headers/CorrosionRepairHeader.js";

function CorrosionRepair() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <CorrosionRepairHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">
            <Container className="text-left">

              <Row className="justify-content-md-center">

                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/korozyon-1.webp")}
                  ></img>
                </Col>

                <Col lg="5" md="12">
                  <h5 className="description">
                    Günümüzde her alanda olduğu gibi inşaat sektöründe de bazı yeniliklerin varlığından söz edilebilir. Yeni nesil binaların yapımında birçok farklı malzemenin kullanılması bu binaları hem daha sağlıklı hem de daha dayanıklı hale getirse de, yapının zamanla yıpranacak olmasının önüne geçememektedir. Zamanla yıpranan binaların ömrünü uzatmak için düzenli bakım da son derece önemli bir konudur. Bu bakım süreçleri içerisinde yer alan korozyon bakımı, yeni nesil binaların en çok ihtiyaç duyduğu gereksinimlerden biridir.

                  </h5>

                  <h5 className="description">
                    Korozyon bakımının yanı sıra, binanın dış cephe boyasının çevresel etkilerden kaynaklanan sürekli aşınması ve yıpranması da farklı açılardan bakım ihtiyacını göstermektedir. Dış cephe boyasının yağmur, kar veya çeşitli toz birikintileri nedeniyle eskimesi ve pul pul dökülmesi, sık sık boyama yapılmasını gerekli kılar. Böyle zamanlarda binaların daha sağlıklı olması için profesyonel firmalardan hizmet almak büyük önem arz etmektedir.

                  </h5>
                </Col>

              </Row>

              <div className="space-50"></div>

              <Row className="justify-content-md-center">
                <Col lg="5" md="12">
                  <h5 className="description">
                    Yeni nesil tesislerin yapımında kullanılmakta olan malzemelerin çeşitli kimyasallara maruz kalması veya elektrokimyasal bozunması korozyon olarak tanımlanabilir. Betonarme yapılarda çeşitli malzemelerin bu korozyona uğraması sonucunda paslanma meydana gelir. Bu pas malzemelerin ömrünü kısaltabilir. Bu bakımın yapılabilmesi için öncelikle korozyonun nasıl oluştuğunun bilinmesi de önemlidir.
                  </h5>

                  <h5 className="description">
                  Korozyona dayanıklı betonarme yapılarda, beton içerisine gömülü çeşitli malzemelerin yüzeyinde koruyucu filmler bulunur. Bu koruyucu oksit filmler zamanla deforme olursa artık malzemeler de bozulmaya açık hale gelir.
                  </h5>

                  <h5 className="description">
                  Yapılarda malzemelerin eskimesinin iki farklı önemli nedeni vardır. Bunlardan biri klor iyonunun etkisi, diğeri ise karbonatlaşma nedeniyle betonun alkali içeriğinin azalmasıdır. Buna bağlı olarak çeşitli dış etkenler ile binalarda çeşitli malzemelerin korozyona uğramasında etkilidir. Bu nedenler; deniz kumu, harçtaki klor iyonu seviyesi, atmosferdeki karbondioksit gazı, deniz suyu, havadaki klor iyonu miktarıdır.
                  </h5>

                  <h5 className="description">
                  Kısaca elektrokimyasal bir olay olan ve pas olarak bilinen bu sorunla baş edebilmek için profesyonel yardım almak gerekir. Korozyon bakımı konusunda sizlere her zaman en iyi çalışanlarla yüksek standartta hizmet sunan firmamız, yapıların ömrünü uzatan son derece detaylı çalışmalar yürütmektedir.
                  </h5>


                </Col>

                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/korozyon-2.webp")}
                  ></img>
                </Col>
              </Row>

              <div className="space-50"></div>

              <Row className="justify-content-md-center">
                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/korozyon-3.webp")}
                  ></img>
                </Col>
                <Col lg="5" md="12">
                  <h5 className="description">
                  Bina, tesis veya yapıların uzun yıllar dayanmasını sağlamak için korozyona karşı ciddi önlemler alınmalıdır. Binaların yapımında kullanılan çeşitli demir ve paslı malzemelerin kaliteli malzemelerle korunması son derece önemlidir.


                  </h5>

                  <h5 className="description">
                  Çeşitli korozyon inhibitörleri ve bazı kimyasallar eklenerek pas etkisinin en aza indirilmesi ve ayrıca seçici koruyucu kaplama işlemleri ile korozyonun çok iyi kontrol edilmesi mümkündür. Oturduğunuz binanın çok daha sağlıklı olması ve deprem gibi afetlerden korunabilmesi için düzenli olarak korozyon bakımı yapılması gerekmektedir.


                  </h5>

                  <h5 className="description">
                  Bina, tesis veya yapılarınız yeniyse bu sorun hemen ortaya çıkmayabilir. Ancak korozyona karşı zamanında önlem alınmazsa, binalarınızın ileride farkına varmadan deforme olacağını bilmelisiniz. Bu nedenle, binalarınızın daha sağlıklı ve güçlü olmasını istiyorsanız, ister eski ister yeni olsun, düzenli olarak korozyon bakımı yapılmalıdır.
                  </h5>

                </Col>
              </Row>
              <div className="space-50"></div>
            </Container>
          </div>
          <MainServices />
          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default CorrosionRepair;
