import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../components/Headers/IndexHeader.js";
import DarkFooter from "../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";

// sections for this page
import Images from "../index-sections/Images.js";
import BasicElements from "../index-sections/BasicElements.js";
import Navbars from "../index-sections/Navbars.js";
import Tabs from "../index-sections/Tabs.js";
import Pagination from "../index-sections/Pagination.js";
import Notifications from "../index-sections/Notifications.js";
import Typography from "../index-sections/Typography.js";
import Javascript from "../index-sections/Javascript.js";
import Carousel from "../index-sections/Carousel.js";
import NucleoIcons from "../index-sections/NucleoIcons.js";
import CompleteExamples from "../index-sections/IndexSecondSection.js";
import SignUp from "../index-sections/SignUp.js";
import Examples from "../index-sections/MainServices.js";
import Download from "../index-sections/Download.js";
import FooterSection from "../index-sections/FooterSection.js";
import ImageGallerySection from "../index-sections/ImageGallerySection.js";
import Contact from "../index-sections/Contact.js";

import ZionWindHeader from "components/Headers/Zion-Wind-Header.js";
import { Container, Row, Col } from "reactstrap";
import ZionWindHizmetler from "views/index-sections/ZionWind-Hizmetler.js";
import ZionWorld from "views/index-sections/ZionWorld.js";
import ZionWindBladeServicesHeader from "components/Headers/ZionWindBladeServicesHeader.js";
import ZionWindTowerServicesHeader from "components/Headers/ZionWindTowerServicesHeader.js";

import { Helmet } from 'react-helmet';
import i18n from "../../Util/i18n";
import languages from "../../Translations";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

function TowerServices() {

  // use useTranslation hook
  const navigation = useNavigate()
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Helmet>
        <meta lang={i18n.language} />
        <title>{t("seo_zion_wind_tower_services.seo_title")}</title>
        <meta name="description" content={t("seo_zion_wind_tower_services.seo_description")} />
      </Helmet>

      <IndexNavbar />
      <div className="wrapper">
        <ZionWindTowerServicesHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">
            <Container className="text-left">
              <Row className="justify-content-md-center">
                <Col lg="5" md="12">
                  <h5 className="description">
                    ZION teknisyenleri, ülkemizde ve yurtdışında bir çok RES projesinde görev almıştır. Ekibimiz kendini sürekli yenileyerek, en son teknoloji ve ekipmanları sahip olduğu tecrübelerine ekleyerek çalışmalarına doğrudan yansıtmaktadır. Rüzgar Türbin projelerimizde görev alan personellerimizin tamamı; başta GWO ST (Global Wind Organisation Safety Training) ve IRATA International (Industrial Rope Access Trade Association) olmak üzere, bir dizi akredite özel eğitimi başarılı bir şekilde tamamlamış, uluslararası bağımsız eğitim kurumları tarafından eğitimleri sertifikalandırılıp, kayıt altına alınmıştır.
                  </h5>
                </Col>
                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/kule-hizmetleri-1.webp")}
                  ></img>
                </Col>
              </Row>

              <div className="space-50"></div>

              <Row className="justify-content-md-center">
                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/kule-hizmetleri-2.webp")}
                  ></img>
                </Col>

                <Col lg="5" md="12">
                  <h2 className="title"> RÜZGAR TÜRBINLERI</h2>
                  <h3>KULE HİZMETLERİMİZ</h3>
                  <h5 className="description">
                    ZION Wind, Rüzgar Türbini Kulelerinin yüzeyinde oluşan kir ve hasarlara en etkin şekilde erişip, müdahale edebilecek tecrübe, ekipman ve uzman teknisyenlere sahiptir. Uygulanan her bir servis çalışması, üretici talimatına uygun, alanında uzman teknisyenler tarafından sağlanmaktadır.
                  </h5>

                  <h3>KULE KONTROL (NDT)</h3>
                  <h5 className="description">
                    NDT, kule yüzeyinin genel durumunu bilmek, zamanında kontrol etmek ve kule ömrünü uzatmak için akılcı bir stratejidir.
                  </h5>

                  <h5 className="description">
                    Burada amaç fabrikasyon hatalarını veya servis sırasında oluşan hasarları ortaya çıkarmak, iklimsel değişiklerle uğramış, kule yüzeyindeki zarar görmüş noktaları tespit etmektir.
                  </h5>

                  <h5 className="description">
                    Genel olarak, NDT pek çok avantaj sunmaktadır. En temelinde kulenizi güven altına alma yatar.
                    Uygulayabildiğimiz, mevcut NDT yöntemleri şunlardır: <br />

                    <ul>
                      <li>Sıvı Emme Testi (PT)</li>
                      <li>Manyetik Parçacık Testi (MT)</li>
                      <li>Radyografik Test (RT)</li>
                      <li>Ultrasonik Test (UT)</li>
                      <li>Görsel Test (VT)</li>
                    </ul>

                  </h5>

                  <h5 className="description">
                    ZION Wind ekibi olarak yapmakta olduğumuz kontroller genel olarak, kule yüzeyindeki hasarı tespit etmeye, çelik NDT testi uygulamaya ve boya ölçümüne yönelik uygulanmaktadır. Kulelerin kontrolünün her yıl düzenli olarak yapılması önerilmektedir.
                  </h5>



                  <h3>KULE HASAR TIPLERI</h3>
                  <h5 className="description">
                    Korozyon (olağandışı şiddetli rüzgar olayı, uzun süren rüzgar kaynaklı titreşimler veya yapının aşırı yüklenmesine bağlı metal yorgunluğu) yapılara zarar verebilir ve felaketlerle sonuçlanabilecek arızalara ya da hasarlara neden olabilir.

                    En yaygın hasar biçimleri,

                    kaynak hasarları

                    beton çatlaması ve

                    kaplamaların bozulmasıdır.

                    Teknisyenlerimiz Boya ve Türbin üreticilerinden kule korozyon tamiri konusunda eğitim ve süpervizörlük hizmeti almış, ülkemizdeki bir çok türbin kulesinde servis sağlamıştır.
                    ZION Wind, müşteri yatırımlarının uzun ömürlü olması için, kule yüzeyinin düzenli bakım ve kontrol yapılmasını önerir.

                  </h5>

                  <h3>KULE YAĞ VE KIR TEMIZLIĞI</h3>
                  <h5 className="description">
                    Rüzgar türbinleri, diğer dış yüzeyler gibi, doğal olarak toz ve diğer parçacık formlarını da biriktirir. Genellikle rüzgar türbinleri ile bu birikim motorlarda ve türbinin diğer bölümlerinde gres ya da hidrolik yağ şeklini alır. Kontrol edilmediğinde, rüzgar türbini çirkin görünmeye başlayabilir. Kirli bir rüzgar türbini kötü bir görüntü yansıtmasının yanı sıra, yağ birikimi ve kirin rüzgar direncini arttırması nedeniyle daha az verimli bir güç eğrisi oluşmaya başlar. Maliyetleri düşürmek için, rüzgar türbini temizlemenizi düzenli muayene veya hizmet programı ile senkronize etmek mümkündür. Araştırmalar, maksimum performans elde etmek için ortalama olarak bir rüzgar türbininin her 5 ila 7 yılda bir temizlenmesi gerektiğini göstermektedir.
                  </h5>

                </Col>
              </Row>
            </Container>
          </div>

          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default TowerServices;
