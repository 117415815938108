import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../../components/Headers/IndexHeader.js";
import DarkFooter from "../../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../../components/Footers/DefaultFooter.js";

// sections for this page
import FooterSection from "../../index-sections/FooterSection.js";
import ImageGallerySection from "../../index-sections/ImageGallerySection.js";

import { Container, Row, Col } from "reactstrap";
import ZionWorld from "../../index-sections/ZionWorld.js";
import { useTranslation } from 'react-i18next';
import MainServices from "../../index-sections/MainServices.js";
import GeotechnicalSolutionHeader from "components/Headers/GeotechnicalSolutionHeader.js";
import CorrosionRepairHeader from "components/Headers/CorrosionRepairHeader.js";
import NtdHeader from "components/Headers/NtdHeader.js";

function Ntd() {
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <NtdHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">
            <Container className="text-left">

              <Row className="justify-content-md-center">

                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/ndt.webp")}
                  ></img>
                </Col>

                <Col lg="7" md="12">
                  <h5 className="description">

                  ZION Industrial, özel sektör, devlet, savunma sanayi, uluslararası ve yerel standartlar, kurallar ve proje şartnamelerine uygun olarak gelenekselden en ileri teknolojiye kadar geniş bir yelpazede tahribatsız muayene yöntemleri sunmaktadır. Bu yöntemler, endüstriyel yatırımların, kritik parça ve ekipmanların güvenilirlik çalışmalarının ve imalat parçalarının denetimlerinin temelini oluşturur. Başarılı bir denetim uygulaması için gerekli olduğuna inandığımız kriterler şunlardır;

                  <ul>
                    <li>Çalıştıkları ortam ve kullandıkları denetim yöntemi konularında uzmanlaşmış teknisyenler.</li>
                    <li>Teknisyenlerimizi destekleyen geniş tabanlı bilgi ve deneyime sahip uzmanlar.</li>
                    <li>Denetimin nasıl yapıldığını ve belirli durumlarda nasıl hareket edileceğini açıklayan ilgili prosedür ve standartların anlaşılması.</li>
                    <li>Kontrol edilecek malzeme için düşünülmüş modern ve doğru kalibre edilmiş cihazlar.</li>
                    <li>Uygulama sırasında izlenen güvenli çalışma ilkeleri ve bu ilkelere uyumu sağlayan iş güvenliği kültürü.</li>
                    <li>Tekrarlanabilir güvenli denetim sonuçları sağlayan standartlaştırılmış kalite sistemi. ZION Endüstriyel, Tahribatsız Muayene Hizmetlerini, kapsamlı iş güvenliği programı, teknik eğitim, kalite ve uzman personeli ile oluşturduğu organizasyon çerçevesinde sunmaktadır.</li>
                  </ul>

                  </h5>

                  <h5 className="description">
                  ZION Industrial olarak, Tahribatsız Muayene Hizmetlerini, kapsamlı iş güvenliği programı, teknik eğitim, kalite ve uzman personeli ile oluşturduğu organizasyon çerçevesinde sunmaktadır.
                  </h5>



                </Col>

              </Row>






              <div className="space-50"></div>
            </Container>
          </div>
          <MainServices />
          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default Ntd;
