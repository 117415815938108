import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../components/Headers/IndexHeader.js";
import DarkFooter from "../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";

// sections for this page
import Images from "../index-sections/Images.js";
import BasicElements from "../index-sections/BasicElements.js";
import Navbars from "../index-sections/Navbars.js";
import Tabs from "../index-sections/Tabs.js";
import Pagination from "../index-sections/Pagination.js";
import Notifications from "../index-sections/Notifications.js";
import Typography from "../index-sections/Typography.js";
import Javascript from "../index-sections/Javascript.js";
import Carousel from "../index-sections/Carousel.js";
import NucleoIcons from "../index-sections/NucleoIcons.js";
import CompleteExamples from "../index-sections/IndexSecondSection.js";
import SignUp from "../index-sections/SignUp.js";
import Examples from "../index-sections/MainServices.js";
import Download from "../index-sections/Download.js";
import FooterSection from "../index-sections/FooterSection.js";
import ImageGallerySection from "../index-sections/ImageGallerySection.js";
import Contact from "../index-sections/Contact.js";

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ZionWindHeader from "components/Headers/Zion-Wind-Header.js";
import { Container, Row, Col } from "reactstrap";
import ZionWindHizmetler from "views/index-sections/ZionWind-Hizmetler.js";
import ZionWorld from "views/index-sections/ZionWorld.js";
import ZionWindBladeServicesHeader from "components/Headers/ZionWindBladeServicesHeader.js";
import ZionWindTowerServicesHeader from "components/Headers/ZionWindTowerServicesHeader.js";
import ZionTrainingHeader from "components/Headers/ZionTrainingHeader.js";

function ZionTraining() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ZionTrainingHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />

          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">
            <Container className="text-left">
              <Row className="justify-content-md-center">

                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/sprat-logo.png")}
                  ></img>
                </Col>
                <Col lg="6" md="12">
                  <h5 className="description">
                    Profesyonel İple Erişim Teknisyenleri Derneği (SPRAT), ekip çalışması, karşılıklı güven ve saygı ortamında açıklık, dürüstlük, profesyonellik, sorumluluk, hesap verebilirlik ve bütünlüğe değer verir. Bu, SPRAT'ın misyonunun yanı sıra üyelerimiz, teknisyenlerimiz ve ortaklarımızla çeşitliliğin ve katılımın onurlandırılmasını ve teşvik edilmesini içerir.
                  </h5>
                </Col>
              </Row>

              <div className="space-50"></div>
              <Row className="justify-content-md-center">
                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/zion-egitim-1.webp")}
                  ></img>
                </Col>

                <Col lg="9" md="12">
                  <h2 className="title">SPRAT Level 1</h2>

                  <h5 className="description">
                    Level 1 SPRAT Eğitimi almış ve Sertifikasyon Sürecini başarıyla tamamlamış bir Teknisyen, aşağıda belirtilmiş olan iple Erişim hizmetlerini kolaylıkla sağlayabilecektir:
                  </h5>


                  <h5 className="description">

                    <ol>
                      <li>iple erişim prosedürlerini, iş planı talimatlarını ve risk değerlendirmelerini anlayabilir ve takip edebilir; </li>
                      <li> her kullanımdan önce kendi iple erişim ekipmanını kontrol etmekten sorumludur;</li>
                      <li>Kıdemli bir teknisyenin gözetimi altında donanım ve diğer işlerde yardımcı olabilir;</li>
                      <li>İnişte basit bir kurtarma yapabilir ve kurtarma operasyonlarına yardımcı olabilir.</li>
                    </ol>
                  </h5>


                  <h5 className="description">
                    Gerekli koşullar:
                    <ul>
                      <li>18 yaşını doldurmuş olması
                      </li>
                      <li>Eğitime katılım için herhangi bir sağlık sorunu olmadığına dair beyan
                      </li>
                    </ul>
                  </h5>

                </Col>
              </Row>


              <div className="space-50"></div>
              <Row className="justify-content-md-center">


                <Col lg="9" md="12">
                  <h2 className="title">SPRAT Level 2</h2>

                  <h5 className="description">
                    Level 2 SPRAT Eğitimi almış ve Sertifikasyon Sürecini başarıyla tamamlamış bir Teknisyen, aşağıda maddelenmiş olan donanımlara sahip olur ve daha karmaşık görevleri kolaylıkla yerine getirebilecektir:

                  </h5>


                  <h5 className="description">

                    <ol>
                      <li>1. Seviye iple Erişim Teknisyenlerinin sahip olduğu tüm beceri ve bilgiye sahiptir; </li>
                      <li> Yaşam hatları, halatlar ve çelik halatlar dahil olmak üzere daha karmaşık istasyonlar inşa edebilir;</li>
                      <li>farklı durumlarda kurtarma önlemleri alabilir;</li>
                      <li>Ekstraksiyon sistemlerini kurabilir ve işletebilir</li>
                    </ol>
                  </h5>

                  <h5 className="description">
                    Gerekli koşullar:
                    <ul>
                      <li>18 yaşını doldurmuş olması
                      </li>
                      <li>Eğitime katılım için herhangi bir sağlık sorunu olmadığına dair beyan - Kayıt defterindeki girişlerle teyit edilen en az bir yıllık mesleki deneyim

                      </li>
                      <li>L1 Teknisyen olarak kayıt defterine girilen en az 500 saat</li>
                    </ul>
                  </h5>

                </Col>
                <Col lg='3' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/zion-egitim-2.webp")}
                  ></img>
                </Col>
              </Row>



              <div className="space-50"></div>
              <Row className="justify-content-md-center">

                <Col lg='4' md="12">
                  <img
                    className="circle img-raised"
                    style={{ width: "100%" }}
                    alt="..."
                    src={require("assets/img/services/zion-egitim-3.webp")}
                  ></img>
                </Col>
                <Col lg="8" md="12">
                  <h2 className="title">SPRAT Level 3</h2>

                  <h5 className="description">
                    Level 3 SPRAT Eğitimi almış ve Sertifikasyon Sürecini başarıyla tamamlamış, oldukça deneyimli bir Teknisyen, planlama ve uygulamadan sorumlu, iple erişim prosedürlerinin tamanına hakim, iş planı ve ilgili çalısma sahası için risk değerlendirmesi yapabilecek kapasitededir.

                  </h5>


                  <h5 className="description">
                    Ayrıca:
                    <ol>
                      <li>1. ve 2. seviye için gerekli bilgi ve becerilerinin tamamına sahiptir;</li>
                      <li>güvenli çalışma için IRATA sisteminin unsurlarını ve ilkelerini anlar;</li>
                      <li>ilgili çalışma tekniklerine ve yasal hükümlere aşinadır;</li>
                      <li>gelişmiş iple erişim ve kurtarma teknikleri konusunda kapsamlı bilgiye sahiptir;</li>
                      <li>Uygun ve güncel bir ilk yardım sertifikasına sahiptir.</li>
                    </ol>

                    Seviye 3, iple erişim için bir güvenlik süpervizörü olabilir: rehberlik için bkz. ICOP [TC-102] Bölüm 2, 2.6.2.
                  </h5>

                  <h5 className="description">
                    Gerekli koşullar:
                    <ul>
                      <li>18 yaşını doldurmuş olması
                      </li>
                      <li>
                        Eğitime katılım için herhangi bir sağlık sorunu olmadığına dair beyan - L2 olarak kayıt defterindeki girişlerle onaylanan en az bir yıllık mesleki deneyim

                      </li>

                      <li>Kayıt defterine L2 olarak girilen en az 500 saat</li>
                    </ul>
                  </h5>

                </Col>

              </Row>
              <div className="space-50"></div>

              <Row className="justify-content-md-center">
                <h2 className="title">SPRAT'a DAIR</h2>

                <h5 className="description">
                  SPRAT, Kuzey Amerika'da iple erişim tekniklerini kullanan sayıları giderek artan şirket ve operatörlerin ihtiyaçlarını karşılamak için 90'ların ortalarında kuruldu.

                </h5>

                <h5 className="description">
                  Endüstride onlarca yıldır çeşitli iple erişim biçimleri kullanılırken, modern iple erişim sistemlerinin gelişimi, iskele ve salıncak sahneleri gibi geleneksel erişim araçları sağlayan endüstrilerin büyümesiyle çatıştı. Bu geleneksel yöntemler, standart düşüş durdurma sistemleri gerektiriyordu.



                </h5>

                <h5 className="description">
                  Kuzey Amerika'da iple erişim sistemlerini desteklemek için özel standartlar ve yasalar olmadan, şirketlerin ve operatörlerin çalışmaları zaman zaman güvenlik yöneticileri ve yasa koyucular tarafından engellendi. SPRAT'ın, İple Erişim Çalışması için Güvenli Uygulamalar ve İple Erişim Çalışması için Sertifikasyon Gereksinimleri dahil olmak üzere endüstri konsensüs standartlarını geliştirmesi, yalnızca iple erişim sistemlerinin güvenliği ve etkinliği konusunda farkındalığı artırmakla kalmadı, aynı zamanda California'nın Endüstriyel İlişkiler Departmanı'nın çabalarının önünü açtı. Kuzey Amerika'da iple erişim sistemlerinin kullanımını onaylayan ilk yasalardan birini oluşturmak. O zamandan beri Alberta ve New York City, SPRAT ve üyelerinin desteğiyle aynı şeyi yaptı.


                </h5>

                <h5 className="description">
                  SPRAT'ın misyonu eğitim, standart geliştirme ve sertifika yönetimi yoluyla iple erişimin güvenli kullanımını ilerletmektir. 2001'den bu yana, düzinelerce şirketi ve devlet kurumunu temsil eden binlerce iple erişim teknisyeni SPRAT standartlarına göre sertifikalandırılmıştır. Şu anda SPRAT, iple erişim kullanan şirketleri ve teknisyenleri düzenleyici destek, ağ oluşturma ve gelişen endüstri konsensüs standartlarına katılma fırsatlarıyla desteklemektedir.


                </h5>



              </Row>





            </Container>
          </div>

          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ZionTraining;
