import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from 'react-i18next';

// core components

function Newsletter() {
  const { t } = useTranslation();

  return (
    <>
      <div className="section">


        <Container className="text-left">

          <Row style={{ flexDirection: 'column' }} className="justify-content-md-center">
            <h2 style={{ margin: 0, textAlign: 'center' }}>{t("Newsletter.title")}</h2>

            <p style={{ textAlign: 'center' }}> {t("Newsletter.description")}</p>

          </Row>

          <Row>
              <div class="card-body">
                <form method="" action="">
                  <div style={{justifyContent:'center', alignContent:'center', alignSelf:'center'}}  class="row">
                    <div class="col-sm-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="now-ui-icons ui-1_email-85"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder={t("Newsletter.mail_placeholder")} />

                      </div>
                      <button type="button" class="btn btn-danger btn-round btn-block">{t("Newsletter.button")}</button>

                    </div>

                  </div>
                </form>
            </div>
          </Row>


        </Container>
      </div>
    </>
  );
}

export default Newsletter;
