import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// core components

function ZionWorld() {
  const [showImage1, setShowImage1] = useState(false);
  const [showImage2, setShowImage2] = useState(false);
  const [showImage3, setShowImage3] = useState(false);
  const { t } = useTranslation();


  return (
    <>
      <div>
        <Container className="text-center">
          <Row>
            <Col>
              <h2 style={{ margin: 0 }} className="text-center">                    {t("ZionWorld.title")}
              </h2>
              <p>                    {t("ZionWorld.description")}
              </p>

              <div className="space-50"></div>

            </Col>
          </Row>

          <Row>
            <Col lg="6" md="12" onMouseEnter={() => setShowImage1(true)} onMouseLeave={() => setShowImage1(false)}>
              <div
                style={{
                  display: "flex",
                  flexDirection: 'column',
                  alignSelf: 'flex-end',
                  justifyContent: 'flex-end',
                  alignContent: 'flex-end',
                  background: `url(${require("assets/img/zion-wind.webp")}) no-repeat bottom`,
                  opacity: showImage1 ? 0.8 : 1,
                  padding: "20px",
                  color: 'white',
                  height: '320px'
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>{t("ZionWorld.image_Title1")}</h2>
                </div>
                <div style={{}}>
                  <Button
                    className="btn-round"
                    style={{ color: 'white' }}
                    color="default"
                    to={"/" + i18next.language + "/zion-wind"}
                    outline
                    tag={Link}
                  >
                    {t("ZionWorld.button")}
                  </Button>
                </div>

              </div>
            </Col>

            <Col lg="6" md="12" onMouseEnter={() => setShowImage2(true)}
              onMouseLeave={() => setShowImage2(false)}>

              <div

                style={{
                  display: "flex",
                  flexDirection: 'column',

                  alignSelf: 'flex-end',
                  justifyContent: 'flex-end',
                  alignContent: 'flex-end',
                  background: `url(${require("assets/img/zion-industrial.webp")}) no-repeat`,
                  opacity: showImage2 ? 0.8 : 1,
                  padding: "20px",
                  color: 'white',
                  height: "320px"
                }}
              >
                <div>
                  <h2 style={{ margin: 0 }}>{t("ZionWorld.image_Title2")}</h2>
                </div>
                <div style={{}}>
                  <Button
                    style={{ color: 'white' }}
                    className="btn-round"
                    color="default"
                    to={"/" + i18next.language + "/iple-erisim-hizmetleri"}
                    outline
                    tag={Link}
                  >
                    {t("ZionWorld.button")}
                  </Button>
                </div>

              </div>



            </Col>






          </Row>
        </Container>
      </div>
    </>
  );
}

export default ZionWorld;
