/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// core components


function DefaultFooter() {
  const { t } = useTranslation();
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href={"/" + i18next.language + "/" + t('Home.url')}
                >
                  {t('Home.1')}
                </a>
              </li>
              <li>
                <a
                  href={"/" + i18next.language + "/" + t('ZionWind.url')}
                >
                  {t('ZionWind.1')}
                </a>
              </li>
              <li>
                <a
                  href={"/" + i18next.language + "/" + t('ZionIndustrial.url')}

                >
                {t('ZionIndustrial.1')}

                </a>
              </li>
              <li>
                <a
                  href={"/" + i18next.language + "/" + t('ZionTraining.url')}

                >
                {t('ZionTraining.1')}

                </a>
              </li>

              <li>
                <a
                  href={"/" + i18next.language + "/" + t('Contact.url')}

                >
                {t('Contact.1')}

                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Designed by{" "}
            <a
              href="https://www.widione.com"
              target="_blank"
            >
              Widione
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
