import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "../../components/Navbars/IndexNavbar.js";
import IndexHeader from "../../components/Headers/IndexHeader.js";
import DarkFooter from "../../components/Footers/DarkFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";

// sections for this page
import Images from "../index-sections/Images.js";
import BasicElements from "../index-sections/BasicElements.js";
import Navbars from "../index-sections/Navbars.js";
import Tabs from "../index-sections/Tabs.js";
import Pagination from "../index-sections/Pagination.js";
import Notifications from "../index-sections/Notifications.js";
import Typography from "../index-sections/Typography.js";
import Javascript from "../index-sections/Javascript.js";
import Carousel from "../index-sections/Carousel.js";
import NucleoIcons from "../index-sections/NucleoIcons.js";
import CompleteExamples from "../index-sections/IndexSecondSection.js";
import SignUp from "../index-sections/SignUp.js";
import Examples from "../index-sections/MainServices.js";
import Download from "../index-sections/Download.js";
import FooterSection from "../index-sections/FooterSection.js";
import ImageGallerySection from "../index-sections/ImageGallerySection.js";
import Contact from "../index-sections/Contact.js";

import i18next from 'i18next';
import ZionWindHeader from "components/Headers/Zion-Wind-Header.js";
import ZionWindHizmetler from "views/index-sections/ZionWind-Hizmetler.js";
import ZionWorld from "views/index-sections/ZionWorld.js";
import ZionWindBladeServicesHeader from "components/Headers/ZionWindBladeServicesHeader.js";
import ActivityAreasHeader from "components/Headers/ActivityAreasHeader.js";

import { Helmet } from 'react-helmet';
import languages from "../../Translations";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import { Button, Container, Row, Col} from "reactstrap";
import { Link } from "react-router-dom";
import CarouselSection from "../index-sections/Carousel.js";


function ActivityAreas() {
  // use useTranslation hook
  const navigation = useNavigate()
  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ActivityAreasHeader />
        <div className="main">
          {/*
          <Navbars />
          <Tabs />
          <Pagination />
          <Notifications />


          <Javascript />

          <NucleoIcons />

          <SignUp />


          */ }
          <div className="section">


            <Container className="text-center">
              <Row className="justify-content-md-center">

                <Col lg="6" md="12">
                  <div
                    className="circle img-raised"
                    style={{
                      display: "flex",
                      flexDirection: 'column',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                      background: `url(${require("assets/img/fa1.webp")}) no-repeat bottom`,
                      color: 'white',
                      height: '320px'
                    }}
                  >
                    <div>
                      <h3 style={{ margin: 0, backgroundColor:'black', opacity: 0.8 }}>{t("FaaliyetAlanlari.1")}</h3>
                    </div>


                  </div>
                </Col>

                <Col lg="6" md="12">
                  <div
                                      className="circle img-raised"

                    style={{
                      display: "flex",
                      flexDirection: 'column',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                      background: `url(${require("assets/img/fa2.webp")}) no-repeat bottom`,
                      color: 'white',
                      height: "320px"
                    }}
                  >
                    <div>
                      <h3 style={{ margin: 0, backgroundColor:'black', opacity: 0.8 }}>{t("FaaliyetAlanlari.2")}</h3>
                    </div>

                  </div>
                </Col>

              </Row>
              <div className="space-50"></div>
              <Row className="justify-content-md-center">
              <Col lg="6" md="12">
                  <div
                                      className="circle img-raised"

                    style={{
                      display: "flex",
                      flexDirection: 'column',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                      background: `url(${require("assets/img/fa3.webp")}) no-repeat bottom`,
                      color: 'white',
                      width:'100%',
                      height: "320px"
                    }}
                  >
                    <div>
                      <h3 style={{ margin: 0, backgroundColor:'black', opacity: 0.8}}>{t("FaaliyetAlanlari.3")}</h3>
                    </div>

                  </div>
                </Col>
                <Col lg="6" md="12">
                  <div
                                      className="circle img-raised"

                    style={{
                      display: "flex",
                      flexDirection: 'column',
                      alignSelf: 'center',
                      justifyContent: 'center',
                      alignContent: 'center',
                      background: `url(${require("assets/img/fa4.webp")}) no-repeat bottom`,
                      color: 'white',
                      width:'100%',
                      height: "320px"
                    }}
                  >
                    <div>
                      <h3 style={{ margin: 0, backgroundColor:'black', opacity: 0.8}}>{t("FaaliyetAlanlari.4")}</h3>
                    </div>

                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <ImageGallerySection />
          <ZionWorld />
          <FooterSection />

        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ActivityAreas;
